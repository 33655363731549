import { DefaultAssembler } from '../assembler/assembler';
import { Controller1 } from '../assembler/controller';
import { HeadlessEditorBackend } from '../assembler/assemblerEditorBackend';
import { Machine, IoRegister } from '../assembler/machine';
import { AssemblerMissionState } from './assemblerMissions';
import {
    UnitVerificationResultSet,
    VerificationError,
    VerificationOk,
    VerificationResult,
    VerificationResultSet,
} from '../app/verificationResults';
import { MissionKind, Task } from '../app/task';
import { Repository } from '../app/repository';
import { MissionProgression } from '../app/missionProgression';
import { LampDevice } from '../assembler/lampDevice';
import { StorageService } from 'common/storage.service';
import { CodeMissionPersistence, MissionSourceFile } from './codeMission';
import { AssemblerMissionComponent } from './AssemblerMissionComponent';


export function verifyBlinkCode(source: string): VerificationResult {
    // (1) Verify syntax of source code
    const assembler = new DefaultAssembler();
    const program = assembler.assemble(source, source);
    if (program.errors.length > 0) {
        return new VerificationError(`Syntax error in assembler code: ${program.errors.first().errorText} `);
    }
    if (program.instructions.length === 0) {
        return new VerificationError('Program does not have any instructions.');
    }
    // (2) Execute code in sandbox
    const lampIo = new LampDevice();
    const lampRegister = new IoRegister(lampIo);
    const machine = new Machine([{from: 0x7fff, to: 0x7fff, device: lampRegister }]);
    const a = new DefaultAssembler();
    const provider = new HeadlessEditorBackend();
    provider.updateCode(source);
    const controller = new Controller1(machine, provider, a);
    /*
     *  Run up to 100 clock cycles and count lamp blinks
     */
    let lampState = false;
    let blinks = 0;
    for (let i = 0; i < 100; i++) {
        controller.tick();
        const newLampState = lampIo.lampIsOn;
        if (newLampState !== lampState) {
            if (newLampState === false) {
                // turned off again, count a blink.
                blinks++;
                if (blinks >= 3) {
                    return new VerificationOk();
                }
            }
            lampState = newLampState;
        }
    }
    return new VerificationError('Ran 100 clock cycles, but did not detect any lamp blinks.');
}

export class AssemblerBlinkMissionState implements AssemblerMissionState {
    code: MissionSourceFile;
    enableDisplay = false;
    enableLamp = true;
    enableKeyboard = false;

    isCompleted = false;
    constructor(readonly mission: Task, private readonly storage: StorageService, readonly data?: CodeMissionPersistence) {
        const code = this.getInitialCode(data);
        this.code = new MissionSourceFile(code, this);
    }
    private getInitialCode(data?: CodeMissionPersistence) {
        if (data) {
            return data.code;
        } else {
            // default source text
            return `# Assembler code \n`;
        }
    }
    save() {
        this.store(this.storage);
    }
    store(storage: StorageService) {
        const data: CodeMissionPersistence = { code: this.code.text };
        const repository = new Repository(storage);
        repository.saveLevel(this.mission.key, data);
    }
    verify(): VerificationResultSet {
        const result = verifyBlinkCode(this.code.text);
        const results = new UnitVerificationResultSet(result);
        this.isCompleted = results.succeeded;
        return results;
    }
    hasState = false;
    resetState() {
        /* not supported in the toolbar, although there is a reset button on the engine */
    }
    getComponent() {
        return (<AssemblerMissionComponent missionState={this} />);
    }
}

export const assemblerBlinkMission = new class implements Task {
    readonly key = 'ASSEMBLER1';
    readonly kind = MissionKind.Custom;
    start(storage: StorageService, _missions: MissionProgression): AssemblerBlinkMissionState {
        return new AssemblerBlinkMissionState(this, storage);
    }
    restore(storage: StorageService, _missions: MissionProgression): AssemblerBlinkMissionState {
        const repository = new Repository(storage);
        const data = repository.getLevelData(this.key) as CodeMissionPersistence;
        return new AssemblerBlinkMissionState(this, storage, data);
    }
};
