import './SyntaxRules.css';
import { GrammarSet } from './earley';
import { ErrorResult, Option } from './shared';
import { CodeGenerationRule } from './codeGeneration';
import { Help } from './Help';
import { PopupHelp, VmInstructionsHelp } from './VmHelp';
import { } from '../common/extensions';

export class SyntaxRule {
    lhsErrors: string[] = [];
    rhsErrors: string[] = [];
    codeGenErrors: string[] = [];
    constructor(readonly lhs: string, readonly rhs: string, readonly codegen?: string) {}
    get production() {
        return this.lhs + ' -> ' + this.rhs;
    }
    get rhsArray(): readonly string[] {
        return this.rhs.trim().split(' ');
    }
    get grammarErrors(): readonly string[] {
        return this.lhsErrors.concat(this.rhsErrors);
    }
    /* resets errors and validates */
    validate() {
        this.lhsErrors = [];
        this.rhsErrors = [];
        this.codeGenErrors = [];
        const lhs = this.lhs.trim();
        const rhs = this.rhsArray;
        if (lhs === '') {
            this.lhsErrors.push('Empty left-hand production');
            return;
        }
        if (!this.isValidIdentifier(lhs)) {
            this.lhsErrors.push('Invalid characters in left hand name.');
            return;
        }
        if (rhs.length === 1 && lhs === rhs[0]) {
            this.rhsErrors.push('Infinite recursion');
            return;
        }
        if (rhs.length === 0) {
            this.rhsErrors.push('Empty right-hand production');
            return;
        }
        if (rhs.length > 1 && (this.codegen === undefined || this.codegen.trim() === '')) {
            this.codeGenErrors.push(`A code generation rule is required for a rule with more than one production on the right-hand side.`);
            return;
        }
        if (this.codegen) {
            const substitutions = this.getSubstitutions(this.codegen);
            for (const substitution of substitutions) {
                const parsed = parseSubstitution(substitution);
                if (!parsed) {
                    this.codeGenErrors.push(`Invalid substitution: [${substitution}].`);
                    return;
                }
                const [name, index] = parsed;
                const matches = rhs.filter(p => p === name);
                if (matches.length === 0) {
                    this.codeGenErrors.push(`'${name}' is not defined in the grammar.`);
                    return;
                }
                if (index === undefined && matches.length > 1) {
                    this.codeGenErrors.push(`'${name}' is ambigous. Use a number like ['${name} 2'].`);
                    return;
                }
                if (index !== undefined && matches.length < index) {
                    this.codeGenErrors.push(`[${substitution}] is invalid. There are only ${matches.length} '${name}'. `);
                    return;
                }
            }
        }
    }
    isValidIdentifier(ident: string) {
        return /^\w[\w\d_]*$/.test(ident);
    }
    getSubstitutions(code: string) {
        const stringSegments = Array.from(code.matchAll(/\[(.*?)\]/g)).map(r => r.itemAt(1));
        return stringSegments;
    }
}

/*
    'Expression'
    or
    'Expression 1'
*/
export function parseSubstitution(macro: string) {
    const m = /^(.*?)(\s+(\d+))?$/.exec(macro);
    if (!m) {
        return undefined;
    }
    const name = m[1];
    const ixStr = m[3];
    const ix = ixStr !== undefined ? parseInt(ixStr) : undefined;
    return [name, ix] as [string, number?];
}

type GrammarRule = {
    readonly lhs: string;
    readonly rhsArray: readonly string[];
};

export function validateGrammarRules(rules: SyntaxRule[], terminals: string[]): Option<GrammarSet> {
    rules.forEach(r => r.validate());
    validateGrammar(rules, terminals);
    const firstWithError = rules.find(r => r.grammarErrors.length > 0);
    if (firstWithError) {
        const firstError = firstWithError.grammarErrors[0]!;
        return new ErrorResult(`Error in grammar rule '${firstWithError.production}': ${firstError}`);
    }
    return grammarTokensToMap(rules);
}

function grammarTokensToMap(rules: GrammarRule[]) {
    const lhsToRhsList: GrammarSet = {};
    for (const rule of rules) {
        const lhs = rule.lhs;
        const rhs = rule.rhsArray;
        if (!lhsToRhsList[lhs]) {
            lhsToRhsList[lhs] = [];
        }
        lhsToRhsList[lhs]!.push(rhs);
    }
    return lhsToRhsList;
}

/* Check that all symbols used on the right hand side is defined */
function validateGrammar(rules: SyntaxRule[], terminals: string[]) {
    // hacky - using Map as a set
    // record all defined symbols (terminals and lhs productions)
    const definedSymbols = new Set<string>();
    terminals.forEach(t => definedSymbols.add(t));
    const usedSymbols = new Set<string>();
    usedSymbols.add('PROGRAM');
    for (const rule of rules) {
        definedSymbols.add(rule.lhs);
        rule.rhsArray.forEach(symbol => usedSymbols.add(symbol));
    }
    // then check if any production uses undefined symbols
    // and that all productions are used
    for (const rule of rules) {
        if (!usedSymbols.has(rule.lhs)) {
            rule.lhsErrors.push(`Symbol '${rule.lhs}' is not used anywhere.`);
        }
        for (const symbol of rule.rhsArray) {
            if (!definedSymbols.has(symbol)) {
                rule.rhsErrors.push(
                    `Right hand side symbol '${symbol}' is not defined. It must be either a token name or the left-hand side of another rule.`
                );
            }
        }
    }
}

/* note: we assume the rules have been validated at this point! */
export function validateCodegenRules(rules: SyntaxRule[]): Option<CodeGenerationRule[]> {
    const firstWithError = rules.find(r => r.codeGenErrors.length > 0);
    if (firstWithError) {
        const firstError = firstWithError.codeGenErrors[0]!;
        return new ErrorResult(`Error in code generation rule '${firstWithError.production}': ${firstError}`);
    }
    return rules.filter(r => r.codegen !== undefined).map(r => ({ production: r.production, code: r.codegen ?? '' }));
}

function VmCodeEditor(props: { value: string, onChange: (code: string) => void }) {
    return (<textarea
        className='codegen'
        value={props.value}
        onChange={e => props.onChange(e.target.value)} />);
}

function RuleRow(props: {
    rule: SyntaxRule;
    index: number;
    onChangeRule: (index: number, rule: SyntaxRule) => void;
    onDeleteRule: (index: number) => void;
    showCodegen: boolean;
}) {
    const rule = props.rule;
    function changeLhs(ix: number, value: string) {
        props.onChangeRule(ix, new SyntaxRule(value, rule.rhs, rule.codegen));
    }
    function changeRhs(ix: number, value: string) {
        props.onChangeRule(ix, new SyntaxRule(rule.lhs, value, rule.codegen));
    }
    function changeCodegen(ix: number, value: string) {
        props.onChangeRule(ix, new SyntaxRule(rule.lhs, rule.rhs, value));
    }
    let codegen = null;
    if (props.showCodegen) {
        const codeGenError =
            rule.codeGenErrors.length > 0 ? <div className="codegen-error alert alert-danger">{rule.codeGenErrors.join(' ')}</div> : null;
        codegen = (
            <>
                <td>
                    <VmCodeEditor value={rule.codegen ?? ''} onChange={code => changeCodegen(props.index, code)} />
                    {codeGenError}
                </td>
            </>
        );
    }
    const hasLhsError = rule.lhsErrors.length > 0;
    const hasRhsError = rule.rhsErrors.length > 0;
    const lhsError = hasLhsError ? <div className="alert alert-danger">{rule.lhsErrors.join(' ')}</div> : null;
    const rhsError = hasRhsError ? <div className="alert alert-danger">{rule.rhsErrors.join(' ')}</div> : null;
    return (
        <tr>
            <td>
                <input
                    className={`lhs form-control ${hasLhsError ? 'is-invalid' : ''}`}
                    value={rule.lhs}
                    onChange={e => changeLhs(props.index, e.target.value)}
                />
                <div className="invalid-feedback">{lhsError}</div>
            </td>
            <td className="arrow">→</td>
            <td>
                <input
                    className={`rhs form-control ${hasRhsError ? 'is-invalid' : ''}`}
                    value={rule.rhs}
                    onChange={e => changeRhs(props.index, e.target.value)}
                />
                <div className="invalid-feedback">{rhsError}</div>
            </td>
            {codegen}
            <td>
                <button className="btn btn-secondary" onClick={() => props.onDeleteRule(props.index)}>
                    <i className="bi bi-trash"></i>
                </button>
            </td>
        </tr>
    );
}

export function SyntaxRules(props: {
    rules: SyntaxRule[];
    parsedGrammar: Option<GrammarSet>;
    showCodegen: boolean;
    onAddRule: () => void;
    onDeleteRule: (index: number) => void;
    onRuleChanged: (index: number, rule: SyntaxRule) => void;
}) {
    const err = props.parsedGrammar instanceof ErrorResult ? <div className="alert alert-danger">{props.parsedGrammar.message}</div> : null;
    const rows = props.rules.map((r, ix) => (
        <RuleRow
            key={ix}
            rule={r}
            index={ix}
            showCodegen={props.showCodegen}
            onDeleteRule={props.onDeleteRule}
            onChangeRule={props.onRuleChanged}
        />
    ));
    const help = !props.showCodegen ?
        <Help>
            <p>Specify the rules for parsing the token into a syntax tree.</p>
            <p>On the left is the name of a production. On the right is one or more names of productions or tokens</p>
        </Help>
        : <Help>
            <p>Specify the assembler code to generate for the syntax node. The code can contain assembler or macro instructions,
                and it can embed the code generated by other symbols in the rule.</p>
            <p>Insert the code for a symbol by enclosing the name in square brackets, like <code>[Expression]</code> </p>
        </Help>;
    return (
        <>
            {help}
            <table className="rules">
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        {props.showCodegen &&
                            <th>Code generation
                                <div className='text-end'>
                                <PopupHelp>
                                    Code in the stack-machine language generated for the syntax.
                                    Code for sub-productions is be inserted by the name in brackets, e.g. [Expression].
                                    This placeholder will be replaced with the code generated for the production.
                                    If there are multiple productions of the same name, use numbers like [Expression 1].
                                    <VmInstructionsHelp />
                                    <p>Shared functions can be added in the Runtime Library so they wont be included multiple times.</p>
                                </PopupHelp>
                                </div>
                            </th>}
                    </tr>
                </thead>
                <tbody>{rows}</tbody>
            </table>
            <button className="btn btn-secondary" onClick={props.onAddRule}>
                Add Rule
            </button>
            {err}
        </>
    );
}
