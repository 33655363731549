import { pairs } from '../../../common/utilities';
import { Connection, Waypoint } from '../../circuitStructure';
import { Pos } from '../../position';
import { InputConnectorLocation } from '../connector/InputConnectorComponent';
import { OutputConnectorLocation } from '../connector/OutputConnectorComponent';
import { generateEndPath, generateFullPath, generateStartPath } from './path';

/*  A wire consist of one or more sections, separated by waypoints.
 */


export interface Segment {
    path: string;
}
class SingleSegment implements Segment {
    constructor(public inputConnectorComponent: InputConnectorLocation,
        public outputConnectorComponent: OutputConnectorLocation) { }
    get path() {
        return generateFullPath(
            this.inputConnectorComponent,
            this.outputConnectorComponent);
    }
}

class StartSegment implements Segment {
    constructor(public inputConnectorComponent: InputConnectorLocation,
        public endPoint: Waypoint) {
    }
    get path() {
        return generateStartPath(new Pos(this.endPoint.x, this.endPoint.y),
            this.inputConnectorComponent);
    }
}

class MidSegment implements Segment {
    constructor(private start: Waypoint, public endPoint: Waypoint) { }
    get path() {
        return `M ${this.start.x},${this.start.y} L ${this.endPoint.x},${this.endPoint.y}`;
    }
}

class EndSegment implements Segment {
    constructor(private start: Waypoint, public outputConnectorComponent: OutputConnectorLocation) { }
    get path() {
        return generateEndPath(
            new Pos(this.start.x, this.start.y),
            this.outputConnectorComponent);
    }
}


export class Wire {
    segments: Segment[] = [];
    outputConnectorComponent: OutputConnectorLocation | undefined;

    /* updates segment list (which MAY create a new array) */
    updateSegments(connection: Connection,
        inputConnectorComponent: InputConnectorLocation,
        outputConnectorComponent: OutputConnectorLocation) {

        const targetChanged = this.outputConnectorComponent !== outputConnectorComponent;
        if (targetChanged) {
            this.outputConnectorComponent = outputConnectorComponent;
        }

        // only create segments when necessary
        // (created segments should retain their identity, otherwise we get problems with mouseleave event)
        if (targetChanged || this.segments.length !== connection.waypoints.length + 1) {
            if (connection.waypoints.length === 0) {
                this.segments = [new SingleSegment(inputConnectorComponent, outputConnectorComponent)];
            } else {
                const waypoints = connection.waypoints;
                this.segments = [new StartSegment(inputConnectorComponent, waypoints.first())];
                for (const [start, end] of pairs(waypoints)) {
                    this.segments.push(new MidSegment(start, end));
                }
                const lastWaypoint = waypoints.at(-1)!;
                this.segments.push(new EndSegment(lastWaypoint, outputConnectorComponent));
            }
        }
    }
}

