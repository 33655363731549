import { diagram } from './missions';
import { nandNodeType, invNodeType, orNodeType, andNodeType, xorNodeType, inv16NodeType, selector16NodeType, and16NodeType } from './logicMissions';
import { add16NodeType, isNegNodeType, isZero16NodeType, zero16NodeType, zeroNodeType } from './arithmeticMissions';
import { bit, word, Pin, PinGroup, pins } from '../pins';
import { numericTest } from '../verification';
import { aluFlagsMappings, jumpFlagsMappings } from '../../assembler/mnemonics';
import { bitArrayToNumber, numberToBoolArray, boolToBit } from '../../common/bits';
import * as Word16 from '../../common/arithmetics';
import { component } from './baseNodeType';
import { OutputRuleArray } from './outputRules';
import { depends } from './dependency';
import { add, bitwiseAnd, inv } from '../../common/arithmetics';


export const unaryAluMission = diagram({
    key: 'ALU_PRESET',
    inputPins: [bit('z'), bit('n'), new Pin(16, 'X', 'signed')],
    outputPins: [word()],
    palette: [inv16NodeType, selector16NodeType, zero16NodeType, nandNodeType, andNodeType, orNodeType],
    tests: [
        // n, z, data
        numericTest([0, 0, 0], [0]),
        numericTest([0, 1, 0], [0xffff]),
        numericTest([1, 0, 0], [0]),
        numericTest([1, 1, 0], [0xffff]),
        numericTest([0, 0, 27], [27]),
        numericTest([0, 1, 27], [Word16.inv(27)]),
        numericTest([1, 0, 27], [0]),
        numericTest([1, 1, 27], [0xffff]),
        numericTest([0, 0, 0xffff], [0xffff]),
        numericTest([0, 1, 0xffff], [0]),
        numericTest([1, 0, 0xffff], [0]),
        numericTest([1, 1, 0xffff], [0xffff]),
    ],
    score: { min: 3 },
} as const);

export const aluUnaryModifierNodeType = component('unary alu',
    'ALU_PRESET',
    pins(bit('z'), bit('n'), word('X')),
    pins(word('')),
    new OutputRuleArray(arr => {
        const [z, n, data] = arr;
        let o = z === 1 ? 0 : data;
        o = n === 1 ? Word16.inv(o) : o;
        return [o];
    }),
    depends(unaryAluMission)
);

function aluTestcase(inp: readonly [string, number, number], o: number) {
    const [op, x, y] = inp;
    const flags = aluFlagsMappings[op];
    if (!flags) {
        throw new Error('op not found: ' + op);
    }
    return [flags, [x, y], [o]] as const;
}

const aluTests = [
    // [op, x, y], o
    [['0', 27, 0xfffc], 0],
    [['1', 27, 0xfffc], 1],
    [['-1', 27, 0xfffc], 0xffff],
    [['X', 27, 0xfffc], 27],
    [['Y', 27, 0xfffc], 0xfffc],
    [['~X', 27, 0xfff0], 65508],
    [['~Y', 27, 0xfff0], 0x000f],
    [['-X', 27, 0xfffc], Word16.neg(27)],
    [['-Y', 27, 0xfffc], Word16.neg(0xfffc)],
    [['X+1', 27, 0xfffc], 28],
    [['Y+1', 27, 0xfffc], 0xfffd],
    [['X-1', 27, 0xfffc], 26],
    [['Y-1', 27, 0xfffc], 0xfffb],
    [['X+Y', 27, 0xfffc], Word16.add(27, 0xfffc)],
    [['X-Y', 27, 0xfffc], Word16.add(27, Word16.neg(0xfffc))],
    [['Y-X', 27, 0xfffc], Word16.add(Word16.neg(27), 0xfffc)],
    [['X&Y', 0b110, 0b011], 0b010],
    [['X|Y', 0b110, 0b011], 0b111]
] as const;

const aluTests1 = aluTests.map(([a, b]) => aluTestcase(a, b));

const aluTestcases = aluTests1.map(([flags, inputs, expected]) =>
    numericTest(flags.concat(inputs), expected));

export const aluNodeTestcases =
    aluTests1.map(([flags, inputs, expected]) => numericTest([bitArrayToNumber(flags)].concat(inputs),
        expected));


export const aluMission = diagram({
    key: 'ALU',
    inputPins: [
        new PinGroup('',
            [bit('zx'), bit('nx'), bit('zy'), bit('ny'), bit('f'), bit('no')]),
        new Pin(16, 'X', 'signed'), new Pin(16, 'Y', 'signed')
    ],
    outputPins: [word('')],
    palette: [
        nandNodeType, and16NodeType, add16NodeType, aluUnaryModifierNodeType, selector16NodeType, inv16NodeType, zeroNodeType
    ],
    tests: aluTestcases,
    score: { min: 6 },
} as const);

export function aluResolve(flags: readonly boolean[], x: number, y: number) {
    const [zx, nx, zy, ny, f, no] = flags;
    x = zx ? 0 : x;
    x = nx ? inv(x) : x;
    y = zy ? 0 : y;
    y = ny ? inv(y) : y;
    let o = f ? add(x, y) : bitwiseAnd(x, y);
    o = no ? inv(o) : o;
    return o;
}

export const aluNodeType = component('alu',
    'ALU',
    pins(new Pin(6, 'op', 'aluOperation'), word('X'), word('Y')),
    pins(word('')),
    new OutputRuleArray(([op, x, y]) => {
        const flags = numberToBoolArray(op, 6);
        const o = aluResolve(flags, x, y);
        return [o];
    }),
    // cannot be expanded due to different number of pins in diagram
    depends(aluMission, 1, false)
);

const conditionTests1: readonly [[string, number], boolean][] = [
    [['NULL', 0], false],
    [['NULL', 1], false],
    [['NULL', 2], false],
    [['NULL', Word16.neg(1)], false],
    [['JMP', 0], true],
    [['JMP', 1], true],
    [['JMP', 2], true],
    [['JMP', Word16.neg(1)], true],
    [['JGT', 0], false],
    [['JGT', 1], true],
    [['JGT', 2], true],
    [['JGT', Word16.neg(1)], false],
    [['JEQ', 0], true],
    [['JEQ', 1], false],
    [['JEQ', 2], false],
    [['JEQ', Word16.neg(1)], false],
    [['JGE', 0], true],
    [['JGE', 1], true],
    [['JGE', 2], true],
    [['JGE', Word16.neg(1)], false],
    [['JLT', 0], false],
    [['JLT', 1], false],
    [['JLT', 2], false],
    [['JLT', Word16.neg(1)], true],
    [['JLE', 0], true],
    [['JLT', 1], false],
    [['JLE', 2], false],
    [['JLE', Word16.neg(1)], true],
    [['JNE', 0], false],
    [['JNE', 1], true],
    [['JNE', 2], true],
    [['JNE', Word16.neg(1)], true],
];

export const conditionNodeTests = conditionTests1.map(
    ([[mn, val], f]) =>
    numericTest([bitArrayToNumber(jumpFlagsMappings[mn]!), val], [boolToBit(f)]));

export const conditionMission = diagram({
    key: 'CONDITION',
    inputPins: [
        new PinGroup('', [bit('lt'), bit('eq'), bit('gt')]),
        new Pin(16, 'X', 'signed')
    ],
    outputPins: [bit('')],
    palette: [
        nandNodeType, invNodeType, andNodeType, orNodeType, xorNodeType, isNegNodeType, isZero16NodeType
    ],
    tests: conditionTests1.map(([[mn, val], f]) => numericTest(jumpFlagsMappings[mn]!.concat([val]),
        [boolToBit(f)])),
    score: { min: 8, nands: 56 },
} as const);

export const conditionNodeType = component('condition',
    'CONDITION',
    pins(new Pin(3, 'cd', 'conditionFlags'), word('X')),
    pins(bit()),
    new OutputRuleArray(([cond, n]) => {
        const [lt, eq, gt] = numberToBoolArray(cond, 3);
        const isNegative = Word16.isNeg(n);
        const j = (lt && isNegative) || (eq && (n === 0)) || (gt && !(isNegative || n === 0));
        return [boolToBit(j)];
    }),
    // cannot be expanded due to different number of pins in diagram
    depends(conditionMission, 1, false)
);

export const resolveCondition = (lt: number, eq: number, gt: number, n: number) => {
    const isNegative = Word16.isNeg(n);
    const j = (lt !== 0 && isNegative) || (eq !== 0 && (n === 0)) || (gt !== 0 && !(isNegative || n === 0));
    return boolToBit(j)
};
