import { diagram, Truth } from './missions';
import { depends, transparent } from './dependency';
import { ComponentCounter, MissionDependency } from '../componentType';
import { Pin, PinGroup, bit, pins, word } from '../pins';
import { numericTest, binaryTest } from '../verification';
import { numberToBoolArray, boolToBit } from '../../common/bits';
import * as Word16 from '../../common/arithmetics';
import { HintPosition, Hint } from '../hint';
import { permutations } from '../../common/utilities';
import {
    nandNodeType, invNodeType, andNodeType, orNodeType, xorNodeType,
    inv16NodeType, splitterNodeType
} from 'diagram/missions/logicMissions';
import { logicalXor } from '../../common/logic';
import { component } from './baseNodeType';
import { OutputRuleArray, OutputRuleBooleanArray } from './outputRules';


/*
 * Arithmetics missions
 */

export const addMission = diagram({
    key: 'HALFADD',
    inputPins: [bit('a'), bit('b')],
    outputPins: [bit('h'), bit('l')],
    palette: [nandNodeType, invNodeType, andNodeType, orNodeType, xorNodeType],
    truth: [
        [[false, false], [false, false]],
        [[false, true], [false, true]],
        [[true, false], [false, true]],
        [[true, true], [true, false]],
    ] as Truth,
    score: { min: 2, nands: 5 }
} as const);


// arithmetic
export const addNodeType = component('add',
    'HALFADD',
    pins(bit('a'), bit('b')),
    pins(bit('h'), bit('l')),
    new OutputRuleBooleanArray(([a, b]) => [(a && b), logicalXor(a, b)]),
    depends(addMission)
);

export const addcMission = diagram({
    key: 'FULLADD',
    inputPins: [bit('a'), bit('b'), bit('c')],
    outputPins: [bit('h'), bit('l')],
    palette: [nandNodeType, invNodeType, andNodeType, orNodeType, xorNodeType, addNodeType],
    truth: [
        [[false, false, false], [false, false]],
        [[false, false, true], [false, true]],
        [[false, true, false], [false, true]],
        [[false, true, true], [true, false]],
        [[true, false, false], [false, true]],
        [[true, false, true], [true, false]],
        [[true, true, false], [true, false]],
        [[true, true, true], [true, true]],
    ] as Truth,
    score: { min: 3, nands: 9 },
} as const);

export const addcNodeType = component('add',
    'ADDC',
    pins(bit('a'), bit('b'), bit('c')),
    pins(bit('h'), bit('l')),
    new OutputRuleBooleanArray(([a, b, c]) =>
        [
            (a && (b || c)) || (b && c),
            logicalXor(logicalXor(a, b), c)
        ]),
    depends(addcMission)
);


export const addMultiMission = diagram({
    key: 'ADD2',
    inputPins: [
        new PinGroup('', [bit('a1'), bit('a0')]),
        new PinGroup('', [bit('b1'), bit('b0')]),
        bit('c')
    ],
    outputPins: [bit('c'), bit('s1'), bit('s0')],
    palette: [nandNodeType, invNodeType, andNodeType, orNodeType, xorNodeType, addcNodeType],
    tests: (permutations([[0, 1, 2, 3], [0, 1, 2, 3], [0, 1]]) as [number, number, number][])
        .map(([a, b, c]: [number, number, number]) =>
            binaryTest(
                numberToBoolArray(a, 2)
                    .concat(numberToBoolArray(b, 2))
                    .concat(numberToBoolArray(c, 1)),
                numberToBoolArray(a + b + c, 3))),
    score: { min: 2, nands: 18 }
} as const);

export const add16NodeType = component('add 16',
    'ADD16',
    pins(word('A'), word('B')),
    pins(word('')),
    new OutputRuleArray(([a, b]) =>
        [Word16.add(a, b)]
    ),
    depends(addcMission, 16, false)
);

export const add16cNodeType = component('add 16',
    'ADDC16',
    pins(word('A'), word('B'), bit('c')),
    pins(bit('c'), word('S')),
    new OutputRuleArray(([a, b, c]) => {
        const [cOut, sum] = Word16.addc(a, b, c);
        return [cOut, sum] as const;
    }),
    depends(addcMission, 16, false)
);

export const zeroNodeType = component('0',
    'ZERO',
    [],
    pins(bit()),
    new OutputRuleArray((_) => [0]),
    transparent()
);

export const incMission = diagram({
    key: 'INC',
    inputPins: [new Pin(16, '', 'signed')],
    outputPins: [word()],
    palette: [nandNodeType, invNodeType, zeroNodeType, xorNodeType, add16cNodeType],
    hints: [
        new Hint('0', '.diagram-output-group',
            HintPosition.Right,
            0, 0,
            140),
        new Hint('1', '.diagram-input-group',
            HintPosition.Right,
            0, 0,
            140),
    ] as Hint[],
    tests: [
        numericTest([0], [1]),
        numericTest([1], [2]),
        numericTest([0xFFFE], [0xFFFF]),
        numericTest([0xFFFF], [0x0000]),
    ],
    score: { min: 2 },
} as const);

export const incNodeType = component('inc 16',
    'INC16',
    pins(word()),
    pins(word()),
    new OutputRuleArray(([a]) =>
        [(a + 1) % (2 ** 16)]
    ),
    depends(incMission)
);

export const zero16NodeType = component('0',
    'ZERO16',
    [],
    pins(word()),
    new OutputRuleArray((_) => [0x0000]),
    transparent()
);

export const subMission = diagram({
    key: 'SUB',
    inputPins: [new Pin(16, 'A', 'signed'), new Pin(16, 'B', 'signed')],
    outputPins: [word()],
    palette: [
        nandNodeType, invNodeType, zeroNodeType, orNodeType, incNodeType, add16cNodeType,
        inv16NodeType
    ],
    tests: [
        numericTest([0, 0], [0]),
        numericTest([1, 0], [1]),
        numericTest([1, 1], [0]),
        numericTest([1, 2], [0xFFFF]),
        numericTest([1, 3], [0xFFFE]),
        numericTest([2, 2], [0]),
        numericTest([4, 2], [2]),
        numericTest([0x8000, 0x8000], [0]),
        numericTest([0x8000, 0x8002], [0xFFFE]),
    ],
    score: { min: 3 },
} as const);

export const sub16NodeType = component('sub 16',
    'SUB16',
    pins(word('A'), word('B')),
    pins(word('')),
    new OutputRuleArray(([a, b]) =>
        [Word16.inv(Word16.add(Word16.inv(a), b))]
    ),
    depends(subMission)
);


export const signMission = diagram({
    key: 'SIGN',
    inputPins: [new Pin(16, '', 'signed')],
    outputPins: [bit()],
    palette: [splitterNodeType],
    tests: [
        numericTest([0], [0]),
        numericTest([1], [0]),
        numericTest([0x7FFF], [0]),
        numericTest([0xFFFF], [1]),
        numericTest([0x8000], [1]),
        numericTest([0xFFFE], [1]),
    ],
    score: { min: 0 },
} as const);

export const isNegNodeType = component('is neg',
    'ISNEG',
    pins(word('')),
    pins(bit('')),
    new OutputRuleArray(([a]) => [boolToBit(Word16.isNeg(a))]),
    depends(signMission, 0)
);

export const isZero4Mission = diagram({
    key: 'ISZERO',
    inputPins: [new PinGroup('', [bit('b3'), bit('b2'), bit('b1'), bit('b0')])],
    outputPins: [bit('')],
    palette: [nandNodeType, invNodeType, andNodeType, orNodeType, xorNodeType],
    tests:
        permutations([[0, 1], [0, 1], [0, 1], [0, 1]])
            .map(bits => numericTest(bits, [boolToBit(!bits.some(b => b !== 0))])),
    score: { min: 4, nands: 10 },
} as const);

export const isZero16NodeType = component('is zero',
    'ISZERO',
    pins(word('')),
    pins(bit('')),
    new OutputRuleArray(([a]) => [a === 0 ? 1 : 0]),
    {
        countNode: true,
        canExpand: false,
        mission: isZero4Mission,
        componentCount(counter: ComponentCounter) { return counter.countForMission(isZero4Mission).mul(4).addNum(6); }
    } as MissionDependency
);





