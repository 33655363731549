import { DiagramType } from '../diagramMissionType';
import { binaryTest } from '../verification';
import { BaseBuiltinComponentType, RuleInternalState } from './baseNodeType';
import { bit, ConstantPin, PinGroup, pins } from '../pins';
import { diagram, Truth } from './missions';
import { OutputRuleBinary } from './outputRules';
import { atomic } from './dependency';


/*
 * Build a nand from simple transistor switches.
 * This is a simplified model - we do not consider EE-level issues like impedance as in the CMOS levels.
    We assume disconnected is same as 0
*/


export const relayOnType = new class extends BaseBuiltinComponentType {
    readonly name = 'on relay';
    readonly key = 'RELAY-ON';
    readonly inputs = pins(bit('c'), bit('in'));
    readonly outputs = pins(bit(''));
    readonly rule = new OutputRuleBinary((base, x) => x && !base);
    readonly depends = atomic();
    readonly displayHint = 'relay-on';
    readonly diagramType = DiagramType.TransistorLevel;
    createInternalState = () => new RuleInternalState(this.rule);
};

export const relayOffType = new class extends BaseBuiltinComponentType {
    readonly name = 'off relay';
    readonly key = 'RELAY-OFF';
    readonly inputs = pins(bit('c'), bit('in'));
    readonly outputs = pins(bit(''));
    readonly rule = new OutputRuleBinary((base, x) => x && base);
    readonly depends = atomic();
    readonly displayHint = 'relay-off';
    readonly diagramType = DiagramType.TransistorLevel;
    createInternalState = () => new RuleInternalState(this.rule);
};


export const nandMission = diagram({
    key: 'RELAY_NAND',
    unlock: true,
    diagramType: DiagramType.TransistorLevel,
    inputPins: [bit('a', 'electric'), bit('b', 'electric'), new PinGroup('', [new ConstantPin('V', 1)])],
    outputPins: [bit('', 'electric')],
    palette: [relayOnType, relayOffType],
    // we cant use the truth-table mechanism, since we also have the drain/sink pins
    tests: [
        // nand: a, b, drain (always 1), sink (always 0)
        binaryTest([false, false, true], [true]),
        binaryTest([false, true, true], [true]),
        binaryTest([true, false, true], [true]),
        binaryTest([true, true, true], [false]),
    ],
    truth: [
        [[false, false, true], [true]],
        [[false, true, true], [true]],
        [[true, false, true], [true]],
        [[true, true, true], [false]],
    ] as Truth,
    score: { min: 2 }
} as const);
