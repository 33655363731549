import { VerificationError, VerificationOk, VerificationResult } from 'app/verificationResults';
import { diagram, DiagramAdapter } from './missions';
import { nandNodeType, invNodeType, andNodeType, orNodeType } from './logicMissions';
import { TestCase, VerificationSubjectAdapter } from '../verification';
import { clockNodeType, ClockState } from './clockNodeType';
import { romNodeType, RomState } from './romNodeType';
import { counterNodeType } from './counterMission';
import { CompositeState2, cpuState2NodeType } from './combinedStateMission';
import { controlUnitNodeType } from './controlUnitMission';
import { diagramMission } from 'app/optionalTrack';
import { RamState } from './ramMissions';
import { toHex } from 'common/hex';

interface CpuTestCase {
    rom: number[];
    verify(clockState: ClockState, memState: CompositeState2, ram: RamState, rom: RomState): VerificationResult;
}

/// Replaces and restores the ROM state
class CpuTestWrapper implements TestCase {
    savedUserProgram?: number[];
    constructor(readonly test: CpuTestCase) { }
    getProgramState(adapter: VerificationSubjectAdapter) {
        const romNode = adapter.findInternalNode(romNodeType);
        if (romNode) {
            const romState = romNode.internalState as RomState;
            return romState.program;
        }
        return undefined;
    }
    setup(adapter: VerificationSubjectAdapter) {
        // Replace the state of the ROM component
        const rom = this.getProgramState(adapter);
        if (rom) {
            this.savedUserProgram = rom.words;
            rom.words = this.test.rom;
        }
    }
    restore(adapter: VerificationSubjectAdapter) {
        const rom = this.getProgramState(adapter);
        if (rom && this.savedUserProgram) {
            rom.words = this.savedUserProgram;
            this.savedUserProgram = undefined;
        }
    }
    verify(adapter: VerificationSubjectAdapter) {
        this.setup(adapter);
        try {
            return this.verify1(adapter);
        } finally {
            this.restore(adapter);
        }
    }
    verify1(adapter: VerificationSubjectAdapter) {
        const d = (adapter as DiagramAdapter).diagram;

        /*
         * We locate the components clock and memory unit
         * (this is actually cheating since the user might not use those exact components)
         */

        const clockComponent = d.nodes.find(n => n.nodeType === clockNodeType);
        if (!clockComponent) {
            return new VerificationError('Clock component missing');
        }
        const clockState = clockComponent.internalState as ClockState;


        const romComponent = d.nodes.find(n => n.nodeType === romNodeType);
        if (!romComponent) {
            return new VerificationError('ROM component missing');
        }
        const romState = romComponent.internalState as RomState;

        /*  Diagram must have a memory unit, so we can test ram
         */
        const memNode = d.nodes.find(n => n.nodeType === cpuState2NodeType);
        if (!memNode) {
            return new VerificationError('Memory component missing');
        }
        const memState = memNode.internalState as CompositeState2;
        const ram = memState.ramState;

        // Reset state of RAM and clock
        d.resetState();

        return this.test.verify(clockState, memState, ram, romState);
    }
}

const storeDTest: CpuTestCase = {
    rom: [0xE310 /* D=~D */],
    verify(clockState: ClockState, memState: CompositeState2, _ram: RamState, _rom: RomState) {
        clockState.tick();
        if (memState.dState.output !== 0xFFFF) {
            const text = `
                <p>Testing with the instruction E310 in ROM address 0. This instruction should cause the value FFFF to be written to the D register.
                <p>After clock cycle, the D register was expected to have value FFFF but was ${toHex(memState.dState.output, 4)}.
            `;
            return new VerificationError(text);
        }
        return new VerificationOk();
    }
};

const advancePcTest:  CpuTestCase = {
    rom: [0x000, 0x0001],
    verify(clockState: ClockState, memState: CompositeState2, ram: RamState, rom: RomState) {
        clockState.tick();
        if (rom.address !== 1) {
            const text = `
                <p>Testing the program counter. Starts at 0.
                After first clock cycle it should advance to 1.
                <p>The current ROM address should be 1 but was ${rom.address}.
            `;
            return new VerificationError(text);
        }
        return new VerificationOk();
    }
}

function programTable(program: number[]) {
    return '<p>Testing with this program in ROM: <table class=program><tr><th>Address</th><th>Instruction</th></tr>'
        + program.map((word, ix) => `<tr><td>${ix}</td><td>${toHex(word, 4)}</td></tr>`).join('') + '</table>';
}
function error(text: string, steps: string[], message: string) {
    return new VerificationError(text + '<p>Steps performed:' + '<ul>'
        + steps.map(l => `<li>${l}</li>`).join('') + '</ul>' + message);
}

const addTest: CpuTestCase = {
    rom: [
        0x0007, /* A = 7 */
        0xE590, /* D = 1 */
        0xE410, /* D = D + A */
    ],
    verify(clockState: ClockState, memState: CompositeState2, _ram: RamState, _rom: RomState) {
        const text = programTable(this.rom);
        const steps = [];

        clockState.tick();
        steps.push('clock cycle');

        if (memState.aState.output !== 7) {
            const text = `
                <p>Testing with the instruction 0007 on ROM address 0. This instruction should cause the value 7 to be written to the A register.
                <p>After clock cycle, the A register was expected to have value 7 but was ${toHex(memState.aState.output, 4)}.
            `;
            return new VerificationError(text);
        }

        clockState.tick();
        steps.push('clock cycle');

        const dState = memState.dState.output;
        if (dState !== 1) {
            const message = `
                <p>Testing with the instruction E590 on ROM address 1. This instruction should cause the value 1 to be written to the D register.
                <p>After clock cycle, the D register was expected to have value FFFF but was ${toHex(memState.dState.output, 4)}.
            `;
            return error(text, steps, message);
        }
        clockState.tick();
        if (memState.dState.output !== 8) {
            const text = `
                <p>Testing with the instruction E410 on ROM address 2. This instruction should cause the sum of A + D to be written to the D register.
                <p>After clock cycle, the D register was expected to have value 8 but was ${toHex(memState.dState.output, 4)}.
            `;
            return new VerificationError(text);
        }
        return new VerificationOk();
    }
};

const ramTest: CpuTestCase = {
    rom: [
        0x0007, /* A = 7 */
        0xE588, /* A* = 1 */
        0xF190, /* D = A* */
    ],
    verify(clockState: ClockState, memState: CompositeState2, _ram: RamState, _rom: RomState) {
        const text = programTable(this.rom);
        const steps = [];

        steps.push('Executing instruction 0007 which should write the number 7 to the A register.');

        clockState.tick();

        steps.push('clock cycle');

        if (memState.aState.output !== 7) {
            const text = `
                <p>Testing with the instruction 0007 on ROM address 0. This instruction should cause the value 7 to be written to the A register.
                <p>After clock cycle, the A register was expected to have value 7 but was ${toHex(memState.aState.output, 4)}.
            `;
            return new VerificationError(text);
        }

        steps.push('Executing instruction E588 which should write 1 to *A, i.e. the RAM address pointed to by A.')

        clockState.tick();
        steps.push('clock cycle');

        const ramState = memState.ramState.peek(7);
        if (ramState !== 1) {
            const message = `
                <p>The value in RAM at address 7 should be 1, but was ${toHex(memState.ramState.peek(7), 4)}.
            `;
            return error(text, steps, message);
        }

        steps.push('Executing F190 which should write *A to D.')

        clockState.tick();
        steps.push('clock cycle');

        const dState = memState.dState.output;
        if (dState !== 1) {
            const message = `
                <p>The value in D should be 1, but was ${toHex(memState.dState.output, 4)}.
            `;
            return error(text, steps, message);
        }

        return new VerificationOk();
    }
};

const jmpCpuTest: CpuTestCase = {
    rom: [0x00FF, 0xE787],
    verify(clockState: ClockState, memState: CompositeState2, ram: RamState, rom: RomState) {
        const text = programTable(this.rom);
        const steps = [];
        steps.push('Executing instruction 00FF. This should cause the value 00FF to be written to the A register.');
        clockState.tick();
        steps.push('Clock cycle.');
        if (memState.aState.output !== 0x00FF) {
            return error(text, steps, `The A register was expected to have value 00FF but was ${toHex(memState.aState.output, 4)}.`);
        }
        const addr = rom.address;
        if (addr !== 1) {
            return error(text, steps, `The ROM address was expected to be 1 but was ${rom.address}.`);
        }
        steps.push('Executing instruction E787. This should cause the value of A (00FF) to be written to PC.');
        clockState.tick();
        steps.push('Clock cycle.');

        if (rom.address !== 0x00FF) {
            return error(text, steps, `The ROM address was expected to be 00FF but was ${toHex(rom.address, 4)}.`);
        }

        return new VerificationOk();
    }
}

export const cpu3Mission = diagram({
    key: 'CPU3',
    inputPins: [],
    outputPins: [],
    palette: [
        nandNodeType, controlUnitNodeType, cpuState2NodeType, invNodeType, andNodeType, orNodeType, romNodeType, counterNodeType, clockNodeType
    ],
    tests: [
        new CpuTestWrapper(storeDTest),
        new CpuTestWrapper(advancePcTest),
        new CpuTestWrapper(addTest),
        new CpuTestWrapper(ramTest),
        new CpuTestWrapper(jmpCpuTest),
    ],
    score: { min: 4 }
} as const);

export const cpu3MissionTask = diagramMission(cpu3Mission);
