import { InputConnectorLocation } from '../connector/InputConnectorComponent';
import { Point, Pos } from '../../position';
import { NodeLocation } from '../node/NodeComponent';
import { OutputConnectorLocation } from '../connector/OutputConnectorComponent';
import { ConnectorLocation } from '../connector/ConnectorLocation';


class LineBuilder {
    path = '';
    pos!: Pos;
    start(start: Point) {
        this.pos = new Pos(start.x, start.y);
        this.path += `m ${start.x},${start.y} `;
    }
    addPointRel(x: number, y: number) {
        this.pos = this.pos.add(x, y);
        this.path += `l ${x},${y} `;
    }
    addPointAbs(x: number, y: number) {
        this.pos = new Pos(x, y);
        this.path += `L ${x},${y} `;
    }

    getPath() {
        return this.path;
    }
}

/*
 * Generates a SVG path
 */
export function generateFullPath(
    inputConnectorComponent: InputConnectorLocation,
    outputConnectorComponent: OutputConnectorLocation) {
    return new PathBuilder()
        .generateFullPath(inputConnectorComponent, outputConnectorComponent)
        .getPath();
}
export function generateManualRelativePath(endPos: Pos,
    inputConnectorComponent: InputConnectorLocation) {
    return new PathBuilder()
        .generateManualRelativePath(endPos, inputConnectorComponent)
        .getPath();
}
export function generateStartPath(startPos: Pos,
    inputConnectorComponent: InputConnectorLocation) {
    return new PathBuilder()
        .generateStartPath(startPos, inputConnectorComponent)
        .getPath();
}
export function generateEndPath(startPos: Pos,
    outputConnectorComponent: OutputConnectorLocation) {
    return new PathBuilder()
        .generateEndPath(startPos, outputConnectorComponent)
        .getPath();
}

class PathBuilder {
    /* distance between node and parallel line */
    readonly margin = 6;
    /* distance between lines */
    readonly spacing = 3;
    /* lenght of vertial "handle" from connector
     * */
    readonly handleHeight = 14;

    // adjust start pos to bottom of connector
    readonly startOffset = new Pos(10, 28);

    /* Corner point x for connector, relative to the connector
     * Left corner will be negative, right positive.
     *
     * x is x-coordinate of the other end point.
     *
     * */
    cornerOffsetX(connectorComponent: ConnectorLocation, x: number) {
        const startNode = connectorComponent.nodeComponent;
        const nodeOffsetX = startNode.canvasPos.x;
        const connectorPosX = connectorComponent.canvasPos.x;
        const connectorIx = connectorComponent.connectorIndex;
        // startpos relative to node
        const connectorOffsetRel = connectorPosX - nodeOffsetX;
        const nodeWidth = startNode.width;
        const margin = this.margin + connectorIx * this.spacing;
        const leftCorner = -connectorOffsetRel - margin;
        const rightCorner = nodeWidth - connectorOffsetRel + margin;
        if (x > leftCorner && x < rightCorner) {
            /* select the corner closest to the connector */
            return (rightCorner < -leftCorner) ? rightCorner : leftCorner;
        }
        /* select the corner closet to the end-pont */
        const useRightCorner = x > 0;
        return useRightCorner ? rightCorner : leftCorner;
    }
    generateStartPath(endPos: Pos,
        inputConnectorComponent: InputConnectorLocation) {
        const [l,] = this.generateStartPath1(endPos, inputConnectorComponent);
        l.addPointAbs(endPos.x, endPos.y);
        return l;
    }
    generateStartPath1(endPos: Pos,
        inputConnectorComponent: InputConnectorLocation): [LineBuilder, [number, number]] {

        const startPos = inputConnectorComponent.canvasPos; //.addPos(this.startOffset);
        return this.generateStartPath2(startPos, endPos, inputConnectorComponent);
    }
    generateStartPath2(startPos: Pos, endPos: Pos,
        inputConnectorComponent: InputConnectorLocation): [LineBuilder, [number, number]] {

        let x = endPos.x - startPos.x;
        const y = endPos.y - startPos.y;

        const l = new LineBuilder();
        l.start(startPos);
        const isFreeNode = inputConnectorComponent.nodeComponent instanceof NodeLocation;

        // draw line downwards to y offset - adjusted for connector ix to avoid overlapping lines
        const connectorIx = inputConnectorComponent.connector.index;
        const startHandleHeight = this.handleHeight + connectorIx * this.spacing;

        // vertical handle
        l.addPointRel(0, startHandleHeight);
        if (y < 0 && isFreeNode) {
            // if arrow point upwards, we wrap around the node
            const startNode = inputConnectorComponent.nodeComponent;

            // the element height does not include connectors
            // so we add 40px to account for that
            const nodeHeight = startNode.height + 40;

            // wrap around lower corner
            const cornerOffsetX = this.cornerOffsetX(inputConnectorComponent, x);
            l.addPointRel(cornerOffsetX, 0);
            x -= cornerOffsetX;

            if (((x > 0) && (x < cornerOffsetX)) || ((x < 0) && (x > cornerOffsetX))) {
                // vertical parallel
                const len = Math.min(-y, nodeHeight);
                l.addPointRel(0, -len);
            }
        }
        return [l, [x, y]];
    }
    generateManualRelativePath(endPos: Pos,
        inputConnectorComponent: InputConnectorLocation) {
        const startPosRel = this.startOffset;
        const [l, ] = this.generateStartPath2(startPosRel, endPos, inputConnectorComponent);
        // When dragging the connector, draw directly to end-point
        l.addPointAbs(endPos.x, endPos.y);
        return l;
    }
    generateFullPath(
        inputConnectorComponent: InputConnectorLocation,
        outputConnectorComponent: OutputConnectorLocation) {

        const endPos = outputConnectorComponent.canvasPos;

        const [l, [x, y]] = this.generateStartPath1(endPos, inputConnectorComponent);

        const endSection = this.generateEndPathStack(new Pos(x, y), outputConnectorComponent);

        const end = endSection.first();
        const start = l.pos;
        this.generateMiddleSection(l, start, end);

        // render end section
        // add points in the end section:
        for (const pos of endSection) {
            l.addPointAbs(pos.x, pos.y);
        }

        return l;
    }
    generateMiddleSection(_l: LineBuilder, _start: Pos, _end: Pos) {
       // simplest, just a direct line!
    }
    generateMiddleSection2(l: LineBuilder, start: Pos, end: Pos) {
        // diagonals at each end, connected with a horizontal or vertial middle segments
        const rel = end.sub(start);
        const distX = Math.abs(rel.x);
        const distY = Math.abs(rel.y);
        const directionX = Math.sign(rel.x);
        const directionY = Math.sign(rel.y);
        if (distX > distY) {
            const diagonalX = directionX * distY / 2;
            const diagonalY = directionY * distY / 2;
            // first diagonal
            l.addPointAbs(start.x + diagonalX, start.y + diagonalY);
            // horizontal line in middle
            const segmentLen = (distX - distY);
            const lineVec = segmentLen * directionX;
            l.addPointRel(lineVec, 0);
            // second diagonal
            l.addPointAbs(end.x - diagonalX, end.y - diagonalY);
        } else {
            const diagonalX = directionX * distX / 2;
            const diagonalY = directionY * distX / 2;
            // first diagonal
            l.addPointAbs(start.x + diagonalX, start.y + diagonalY);
            // vertical line in the middle
            const lineLen = (distY - distX) / 2;
            const lineVec = lineLen * directionY;
            l.addPointRel(0, lineVec);
            // diagonal segment
            l.addPointAbs(end.x - diagonalX, end.y - diagonalY);
        }
    }
    generateMiddleSection1(l: LineBuilder, start: Pos, end: Pos) {
        // diagonal in the middle, connected with equal horizontal or vertial segments
        const rel = end.sub(start);
        const distX = Math.abs(rel.x);
        const distY = Math.abs(rel.y);
        const directionX = Math.sign(rel.x);
        const directionY = Math.sign(rel.y);
        if (distX > distY) {
            // horizontal line in both ends
            const segmentLen = (distX - distY) / 2;
            const lineVec = segmentLen * directionX;
            // first horizontal segment
            l.addPointAbs(start.x + lineVec, start.y);
            // diagonal segment
            l.addPointAbs(end.x - lineVec, end.y);
        } else {
            // vertical line in both ends
            const lineLen = (distY - distX) / 2;
            const lineVec = lineLen * directionY;
            // first horizontal segment
            l.addPointAbs(start.x, start.y + lineVec);
            // diagonal segment
            l.addPointAbs(end.x, end.y - lineVec);
        }
    }
    generateEndPath(startPos: Pos, outputConnectorComponent: OutputConnectorLocation) {
        const l = new LineBuilder();
        l.start(startPos);
        const endSection = this.generateEndPathStack(startPos, outputConnectorComponent);
        // add points in the end section:
        for (const pos of endSection) {
            l.addPointAbs(pos.x, pos.y);
        }
        return l;
    }
    generateEndPathStack(startPos: Pos, outputConnectorComponent: OutputConnectorLocation) {
        // end section
        // (build backwards as a stack)
        const endSection: Pos[] = [];

    /* Separate wires going left or right (not very refined) */
        /*
        const direction = Math.sign(-endPos.x);
        endPos = endPos.add(direction * 3, 0);
        */
        const endPos = outputConnectorComponent.canvasPos;
        const isFreeNode = outputConnectorComponent.nodeComponent instanceof NodeLocation;

        const outputConnectorIx = outputConnectorComponent.connector.index;
        const endHandleHeight = this.handleHeight + outputConnectorIx * this.spacing;
        // position of the "handle" end above the connector
        const endY = endPos.y - endHandleHeight;
        if (startPos.y < 0 && isFreeNode) {
            // if line is coming from below
            // create a corner above the target connector
            const cornerOffsetX =
                this.cornerOffsetX(outputConnectorComponent, -startPos.x);
            // line to corner
            endSection.push(new Pos(endPos.x + cornerOffsetX, endY));
            // horizontal from corner
            endSection.push(new Pos(endPos.x, endY));
        }

        // a straight line to handle end
        endSection.push(new Pos(endPos.x, endY));

        // line down to connector
        endSection.push(new Pos(endPos.x, endPos.y));

        return endSection;
    }
}
