import { DefaultAssembler } from '../assembler/assembler';
import { Controller1 } from '../assembler/controller';
import { HeadlessEditorBackend } from '../assembler/assemblerEditorBackend';
import { Machine, IoRegister } from '../assembler/machine';
import { AssemblerMissionState } from './assemblerMissions';
import { CodeMissionPersistence, MissionSourceFile } from './codeMission';
import {
    UnitVerificationResultSet,
    VerificationError,
    VerificationOk,
    VerificationResult,
    VerificationResultSet,
} from '../app/verificationResults';
import { MissionKind, Task } from '../app/task';
import { Repository } from '../app/repository';
import { MissionProgression } from '../app/missionProgression';
import { StorageService } from 'common/storage.service';
import { KeyboardDevice } from 'app/KeyboardComponent';
import { AssemblerMissionComponent } from './AssemblerMissionComponent';


export function verifyReadKeyboardCode(source: MissionSourceFile): VerificationResult {
    // (1) Verify syntax of source code
    const assembler = new DefaultAssembler();
    const program = assembler.assemble(source.text, source);
    if (program.errors.length > 0) {
        return new VerificationError(`Syntax error in assembler code: ${program.errors.first().errorText} `);
    }
    if (program.instructions.length === 0) {
        return new VerificationError('Program does not have any instructions.');
    }
    // (2) Execute code in sandbox
    const keyboardDevice = new KeyboardDevice();
    const keyboardRegister = new IoRegister(keyboardDevice);
    const machine = new Machine([{from: 0x6000, to: 0x6000, device: keyboardRegister }]);
    const a = new DefaultAssembler();
    const provider = new HeadlessEditorBackend();
    provider.updateCode(source.text);
    const controller = new Controller1(machine, provider, a);

    keyboardDevice.setState(0);
    controller.tickMultiple(10);

    keyboardDevice.setState(0x41 /* A */);
    controller.tickMultiple(10);

    keyboardDevice.setState(0);
    controller.tickMultiple(10);

    const char1 = machine.ram.get(0x1000);
    if (char1 !== 0x41) {
        return new VerificationError('Presed "A" (0x41) for 10 clock cycles. Expected memory 0x1000 to be 0x41.');
    }
    keyboardDevice.setState(0x42 /* B */);
    controller.tickMultiple(10);

    keyboardDevice.setState(0);
    controller.tickMultiple(10);

    const char2 = machine.ram.get(0x1001);
    if (char2 !== 0x42) {
        return new VerificationError('Presed "B" (0x42) for 10 clock cycles. Expected memory 0x1001 to be 0x42.');
    }

    return new VerificationOk();
}

export class ReadKeyboardMissionState implements AssemblerMissionState {
    code: MissionSourceFile;
    enableDisplay = false;
    enableLamp = false;
    enableKeyboard = true;

    isCompleted = false;
    constructor(readonly mission: Task, private readonly storage: StorageService, readonly data?: CodeMissionPersistence) {
        const code = this.getInitialCode(data);
        this.code = new MissionSourceFile(code, this);
    }
    private getInitialCode(data?: CodeMissionPersistence) {
        if (data) {
            return data.code;
        } else {
            // default source text
            return `# Assembler code \n`;
        }
    }
    save() {
        this.store(this.storage);
    }
    store(storage: StorageService) {
        const data: CodeMissionPersistence = { code: this.code.text };
        const repository = new Repository(storage);
        repository.saveLevel(this.mission.key, data);
    }
    verify(): VerificationResultSet {
        const result = verifyReadKeyboardCode(this.code);
        const results = new UnitVerificationResultSet(result);
        this.isCompleted = results.succeeded;
        return results;
    }
    hasState = false;
    resetState() {
        /* not supported in the toolbar, although there is a reset button on the engine */
    }
    getComponent() {
        return (<AssemblerMissionComponent missionState={this} />);
    }
}

export const readKeyboardMission = new class implements Task {
    readonly key = 'READ_KEYBOARD';
    readonly kind = MissionKind.Custom;
    start(storage: StorageService, _missions: MissionProgression): ReadKeyboardMissionState {
        return new ReadKeyboardMissionState(this, storage);
    }
    restore(storage: StorageService, _missions: MissionProgression): ReadKeyboardMissionState {
        const repository = new Repository(storage);
        const data = repository.getLevelData(this.key) as CodeMissionPersistence;
        return new ReadKeyboardMissionState(this, storage, data);
    }
};
