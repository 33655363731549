export function lineCharPos(source: string, charIx: number): [number, number] {
    const lines = source.split(/\n/);

    // find line/col position of the token char pos
    let lineStartCharIx = 0;
    let lineIx = 0;
    let colIx = 0;
    for (let i = 0; i < lines.length; i++) {
        /* Add 1 because of the removed \n */
        const endIx = lineStartCharIx + lines.itemAt(i).length + 1;
        if (endIx > charIx) {
            lineIx = i;
            colIx = charIx - lineStartCharIx;
            break;
        }
        lineStartCharIx = endIx;
    }
    return [lineIx, colIx];
}

function pad(padCount: number) {
    return ' '.repeat(padCount);
}
function leftPad(num: number, minLen: number) {
    const numStr = num.toString();
    const padCount = Math.max(minLen - numStr.length, 0);
    return ' '.repeat(padCount) + numStr;
}
export function showContext(source: string, charIx: number) {
    // -1 is used to indicate end-of-source
    if (charIx === -1) {
        charIx = source.length;
    }
    const [lineIx, colIx] = lineCharPos(source, charIx);
    const buffer = [];

    // line numbers are displayed as 1-indexed, but columns as 0-indexed
    buffer.push(`(${lineIx + 1}:${colIx})`);

    const lines = source.split(/\n/);
    // three lines before and three after
    const from = Math.max(lineIx - 2, 0);
    const to = Math.min(lineIx + 4, lines.length);
    const numSize = (to + 1).toString().length;
    for (let i = from; i < to; i++) {
        const isLine = i === lineIx;
        const first = isLine ? '>' : ' ';
        buffer.push(first + ' ' + leftPad(i + 1, numSize) + ' | ' + lines.itemAt(i));
        if (isLine) {
            buffer.push('  ' + pad(numSize) + ' | ' + pad(colIx) + '^');
        }
    }

    return buffer.join('\n');
}
