import { StateView } from "diagram/componentType";
import { IComponentInstanceState } from '../../componentType';
import { LedLamp } from "engine/devices/Led";
import './Relay.css';

function ElecticState({ state } : { state: boolean }) {
    return (<div className='state-view state relay-state'>
        <span className="state">{ state ? '1' : '0' }</span>
    </div>);
}

export function Toggle({ state, onToggle } : { state: boolean, onToggle: (state: boolean) => void  }) {
    const wireClass = 'connection-line wire ' + (state ? 'on ' : '');
    return (<div onClick={() => onToggle(!state)} className='toggle-box'>
            <svg viewBox="0 0 100 100" stroke="black" width="50" height="50" strokeWidth="8">
                <line x1="80" y1="10" x2="80" y2="0" strokeWidth="6" className={wireClass} />
                <circle cx="80" cy="20" r="8" className={wireClass} />
                { state
                    ? <line x1="80" y1="20" x2="80" y2="80" strokeWidth="6" className={wireClass} />
                    : <line x1="80" y1="80" x2="40" y2="30" strokeWidth="6" className="connection-line wire on" /> }
                <circle cx="80" cy="80" r="8" className="connection-line wire on" />
                <line x1="80" y1="90" x2="80" y2="100" strokeWidth="6" className="connection-line wire on" />
            </svg>
        <ElecticState state={state} />
    </div>);
}


/** Shows output state with a lamp. Used on first level */
export function ElectricOutput(props: { stateView: StateView; }) {
    const state = props.stateView.getState() as boolean;
    const wireClass = 'connection-line wire ' + (state ? 'on ' : '');
    return (
        <div className="electric-output">
            <div className="lamp-with-wire">
                <LedLamp state={state} />
                <svg viewBox="0 0 10 10" stroke="black" width="10" height="10" strokeWidth="8">
                    <line x1="5" y1="0" x2="5" y2="10" strokeWidth="6" className={wireClass} />
                </svg>
            </div>
            <ElecticState state={state} />
        </div>);
}


export function DiagramNodeRelayOn(props: { node: IComponentInstanceState}) {

    const inputs =  props.node.inputConnectorStates;
    const outputs =  props.node.outputConnectorStates;

    return (
    <div className='relay-box'>
        <svg width='66' height='60'>
            <line x1='50' y1='0' x2='53' y2='10' strokeWidth='4' className={'wire' + (outputs.itemAt(0).state ? ' on' : '')}></line>
            <line x1='53' y1='10' x2='53' y2='16' strokeWidth='4' className={'wire' + (outputs.itemAt(0).state ? ' on' : '')}></line>
            <circle cx='53' cy='18' r='2' className={'wire' + (outputs.itemAt(0).state ? ' on' : '')}></circle>
            <circle cx='40' cy='18' r='2' className='wire-dot'></circle>
            { !inputs.itemAt(0).state && (
                <line x1='51' y1='14' x2='53' y2='52' strokeWidth='4' className={'wire' + (inputs.itemAt(1).state ? ' on' : '')}></line>)}
            { inputs.itemAt(0).state && (
                <line x1='42' y1='16' x2='53' y2='52' strokeWidth='4' className={'wire' + (inputs.itemAt(1).state ? ' on' : '')}></line>)}

            <circle cx='53' cy='52' r='3' className={'wire' + (inputs.itemAt(1).state ? ' on' : '')}></circle>
            <line x1='53' y1='52' x2='53' y2='58' strokeWidth='4' className={'wire' + (inputs.itemAt(1).state ? ' on' : '')}></line>
            <rect x='14' y='29' width='26' height='12' className={'magnet' + (inputs.itemAt(0).state ? ' on' : '')}></rect>
            <path d='M 24 35
                    a 4 14, 0, 0, 0, -8 0
                    a 6 14, 0, 0, 0, 12 0
                    a 4 14, 0, 0, 0, -8 0
                    a 6 14, 0, 0, 0, 12 0
                    a 4 14, 0, 0, 0, -8 0
                    a 6 14, 0, 0, 0, 12 0
                    a 4 14, 0, 0, 0, -8 0' className={'coil' + (inputs.itemAt(0).state ? ' on' : '')}></path>
            <line x1='20' y1='40' x2='20' y2='58' className={'coil' + (inputs.itemAt(0).state ? ' on' : '')}></line>
        </svg>
    </div>);
}


export function DiagramNodeRelayOff(props: {node: IComponentInstanceState}) {

    const inputs =  props.node.inputConnectorStates;
    const outputs =  props.node.outputConnectorStates;

    return (
        <div className='relay-box'>
            <svg width='66' height='60'>
                <line x1='35' y1='0' x2='40' y2='18' className={'wire' + (outputs.itemAt(0).state ? ' on' : '')}></line>
                <circle cx='53' cy='18' r='2' className='wire-dot'></circle>
                <circle cx='40' cy='18' r='2' className={'wire' + (outputs.itemAt(0).state ? ' on' : '')}></circle>
                { !inputs.itemAt(0).state && (
                    <line x1='51' y1='14' x2='53' y2='52' strokeWidth='4' className={'wire' + (inputs.itemAt(1).state ? ' on' : '')}></line>)}
                { inputs.itemAt(0).state && (
                    <line x1='42' y1='16' x2='53' y2='52' strokeWidth='4' className={'wire' + (inputs.itemAt(1).state ? ' on' : '')}></line>)}
                <circle cx='53' cy='52' r='3' className={'wire' + (inputs.itemAt(1).state ? ' on' : '')}></circle>
                <line x1='53' y1='52' x2='53' y2='58' strokeWidth='4' className={'wire' + (inputs.itemAt(1).state ? ' on' : '')}></line>
                <rect x='14' y='29' width='26' height='12' className={'magnet' + (inputs.itemAt(0).state ? ' on' : '')}></rect>
                <path d='M 24 35
                        a 4 14, 0, 0, 0, -8 0
                        a 6 14, 0, 0, 0, 12 0
                        a 4 14, 0, 0, 0, -8 0
                        a 6 14, 0, 0, 0, 12 0
                        a 4 14, 0, 0, 0, -8 0
                        a 6 14, 0, 0, 0, 12 0
                        a 4 14, 0, 0, 0, -8 0' className={'coil' + (inputs.itemAt(0).state ? ' on' : '')}></path>
                <line x1='20' y1='40' x2='20' y2='58' className={'coil' + (inputs.itemAt(0).state ? ' on' : '')}></line>
            </svg>
        </div>);
}

export function DiagramNodeRelayDouble(props: {node: IComponentInstanceState}) {

    const inputs =  props.node.inputConnectorStates;
    const outputs =  props.node.outputConnectorStates;

    return (
        <div className='relay-box'>
            <svg width='66' height='60'>
                <line x1='35' y1='0' x2='40' y2='18' className={'wire' + (outputs.itemAt(0).state ? ' on' : '')}></line>
                <circle cx='53' cy='18' r='2' className='wire-dot'></circle>
                <circle cx='40' cy='18' r='2' className={'wire' + (outputs.itemAt(0).state ? ' on' : '')}></circle>
                { !inputs.itemAt(0).state && (
                    <line x1='51' y1='14' x2='53' y2='52' strokeWidth='4' className={'wire' + (inputs.itemAt(1).state ? ' on' : '')}></line>)}
                { inputs.itemAt(0).state && (
                    <line x1='42' y1='16' x2='53' y2='52' strokeWidth='4' className={'wire' + (inputs.itemAt(1).state ? ' on' : '')}></line>)}
                <circle cx='53' cy='52' r='3' className={'wire' + (inputs.itemAt(1).state ? ' on' : '')}></circle>
                <line x1='53' y1='52' x2='53' y2='58' strokeWidth='4' className={'wire' + (inputs.itemAt(1).state ? ' on' : '')}></line>
                <rect x='14' y='29' width='26' height='12' className={'magnet' + (inputs.itemAt(0).state ? ' on' : '')}></rect>
                <path d='M 24 35
                        a 4 14, 0, 0, 0, -8 0
                        a 6 14, 0, 0, 0, 12 0
                        a 4 14, 0, 0, 0, -8 0
                        a 6 14, 0, 0, 0, 12 0
                        a 4 14, 0, 0, 0, -8 0
                        a 6 14, 0, 0, 0, 12 0
                        a 4 14, 0, 0, 0, -8 0' className={'coil' + (inputs.itemAt(0).state ? ' on' : '')}></path>
                <line x1='20' y1='40' x2='20' y2='58' className={'coil' + (inputs.itemAt(0).state ? ' on' : '')}></line>
            </svg>
        </div>);
}
