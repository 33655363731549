import { word, Pin, PinGroup, bit, pins } from 'diagram/pins';
import {
    nandNodeType, invNodeType, andNodeType, xorNodeType, orNodeType, inv16NodeType, splitterNodeType, bundlerNodeType, selector16NodeType, and16NodeType
} from 'diagram/missions/logicMissions';
import { zeroNodeType, incNodeType, add16cNodeType, sub16NodeType, add16NodeType, isZero16NodeType, isNegNodeType } from 'diagram/missions/arithmeticMissions';
import { IOTestCase, numericTest } from 'diagram/verification';
import { diagram, Truth } from 'diagram/missions/missions';
import { component } from 'diagram/missions/baseNodeType';
import { OutputRuleArray } from 'diagram/missions/outputRules';
import { depends } from 'diagram/missions/dependency';


export const norMission = diagram({
    key: 'NOR',
    unlock: true,
    inputPins: [bit('a'), bit('b')],
    outputPins: [bit('')],
    palette: [nandNodeType, invNodeType, andNodeType, orNodeType],
    truth: [
        [[false, false], [true]],
        [[false, true], [false]],
        [[true, false], [false]],
        [[true, true], [false]],
    ] as Truth,
    score: { min: 2, nands: 4 }
} as const);


export const xnorMission = diagram({
    key: 'XNOR',
    unlock: true,
    inputPins: [bit('a'), bit('b')],
    outputPins: [bit('')],
    palette: [nandNodeType, invNodeType, andNodeType, orNodeType, xorNodeType],
    truth: [
        [[false, false], [true]],
        [[false, true], [false]],
        [[true, false], [false]],
        [[true, true], [true]],
    ] as Truth,
    score: { min: 2, nands: 5 }
} as const);

const shl = (x: number) => (x << 1) &  0xFFFF;
const shr = (x: number) => ((x & 0xFFFF) >> 1) &  0xFFFF;
const sar = (x: number) => (((x & 0xFFFF) >> 1) & 0xFFFF) | (x & 0x8000);
const samples = [
    0, 1, 2, 4,
    0x0100,
    0x0100,
    0x0200,
    0x0400,
    0x0800,
    0x1000,
    0x2000,
    0x4000,
    0x8000,
];
const shlTests = samples.map(x => numericTest([x], [shl(x)]))
const shrTests = samples.map(x => numericTest([x], [shr(x)]))
const shraTests = samples.map(x => numericTest([x], [sar(x)]))

export const shlMission = diagram({
    key: 'SHL',
    unlock: true,
    inputPins: pins(word()),
    outputPins: pins(word()),
    palette: [nandNodeType, invNodeType, andNodeType, orNodeType, xorNodeType, splitterNodeType, bundlerNodeType, zeroNodeType],
    tests: shlTests,
    score: { min: 0 }
} as const);

export const shlNodeType = component('shl 1',
    'SHL',
    pins(word()),
    pins(word()),
    new OutputRuleArray(([a]) => [shl(a)]),
    depends(shlMission)
);

export const shrMission = diagram({
    key: 'SHR',
    unlock: true,
    inputPins: pins(word()),
    outputPins: pins(word()),
    palette: [nandNodeType, invNodeType, andNodeType, orNodeType, xorNodeType, splitterNodeType, bundlerNodeType, zeroNodeType],
    tests: shrTests,
    score: { min: 0 }
} as const);

export const shrNodeType = component('shr 1',
    'SHR',
    pins(word()),
    pins(word()),
    new OutputRuleArray(([a]) => [shr(a)] ),
    depends(shrMission)
);

export const sarMission = diagram({
    key: 'SAR',
    unlock: true,
    inputPins: [word()],
    outputPins: [word()],
    palette: [nandNodeType, invNodeType, andNodeType, orNodeType, xorNodeType, splitterNodeType, bundlerNodeType, zeroNodeType],
    tests: shraTests,
    score: { min: 0 }
} as const);

export const sarNodeType = component('sar 1',
    'SAR',
    pins(word()),
    pins(word()),
    new OutputRuleArray(([a]) => [sar(a)] ),
    depends(sarMission)
);

export const maxMission = diagram({
    key: 'MAX16',
    unlock: true,
    inputPins: [new Pin(16, 'A'), new Pin(16, 'B')],
    outputPins: [word()],
    palette: [
        nandNodeType, invNodeType, orNodeType, incNodeType,
        add16NodeType, sub16NodeType, isZero16NodeType, isNegNodeType,
        selector16NodeType,
        inv16NodeType
    ],
    tests: [
        numericTest([0, 0], [0]),
        numericTest([1, 0], [1]),
        numericTest([0, 1], [1]),
        numericTest([1, 1], [1]),
        numericTest([1, 2], [2]),
        numericTest([0x7000, 2], [0x7000]),

    ],
    score: undefined /* TODO */,
} as const);

export const maxNodeType = component('max',
    'MAX16',
    pins(word(), word()),
    pins(word()),
    new OutputRuleArray(([a, b]) =>
        [a > b ? a : b]
    ),
    depends(maxMission, 1)
);

export const mulMission = diagram({
    key: 'MUL16',
    unlock: true,
    inputPins: [new Pin(16, 'A', 'signed'), new Pin(16, 'B', 'signed')],
    outputPins: [word()],
    palette: [
        nandNodeType, invNodeType, zeroNodeType, orNodeType, incNodeType,
        add16cNodeType, selector16NodeType,
        inv16NodeType, shlNodeType, and16NodeType,
        bundlerNodeType, splitterNodeType
    ],
    tests: [
        numericTest([0, 0], [0]),
        numericTest([1, 0], [0]),
        numericTest([0, 1], [0]),
        numericTest([1, 1], [1]),
        numericTest([1, 2], [2]),
        numericTest([1, 3], [3]),
        numericTest([2, 2], [4]),
        numericTest([4, 2], [8]),
        numericTest([23, 97], [2231]),
    ],
    score: undefined /* TODO */,
} as const);


export const barrelShlMission = diagram({
    key: 'SHL16',
    unlock: true,
    inputPins: [new PinGroup('', [bit('3'), bit('2'), bit('1'), bit('0')]), new Pin(16, 'X')],
    outputPins: [word()],
    palette: [
        nandNodeType, invNodeType, orNodeType, incNodeType,
        shlNodeType, selector16NodeType
    ],
    tests: [
        new IOTestCase([0, 0, 0, 0, 27], [27]),
        new IOTestCase([0, 0, 0, 1, 27], [27 << 1]),
        new IOTestCase([0, 0, 1, 1, 27], [27 << 3]),
        new IOTestCase([1, 1, 1, 1, 0xFFFF], [0b1000_0000_0000_0000])
    ],
    score: undefined, // TODO
});

export const barrelShlNodeType = component('shl n',
    'BARREL_SHL',
    pins(new Pin(4), word()),
    pins(word()),
    new OutputRuleArray(([count, a]) =>
        [(a << count) & 0xFFFF]
    ),
    depends(barrelShlMission)
);

export const barrelShrNodeType = component('shr n',
    'BARREL_SHR',
    pins(new Pin(4, 'n'), new Pin(16, 'X')),
    pins(new Pin(16)),
    new OutputRuleArray(([count, a]) =>
        [(a >> count) & 0xFFFF]
    ),
    depends(barrelShlMission)
);


export const barrelShr22NodeType = component('shr 22',
    'BARREL_SHR22',
    pins(new Pin(4, 'n'), new Pin(22, 'X')),
    pins(new Pin(22)),
    new OutputRuleArray(([count, a]) =>
        [(a >> count) & 0xFFFF]
    ),
    depends(barrelShlMission)
);
