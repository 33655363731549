import React, { useState } from 'react';
import { enTexts } from '../locale/text.service';
import './TextManager.css';

type DictionaryNode = { [key:string]:TextNode }
type TextNode = string | DictionaryNode;
type Path = string[];

function Collapsible(props: {title:string, children: React.ReactNode}) {
    const [collapsed, setCollapsed] = React.useState(false);
    return <div>
        <div>
        <button className='btn btn-outline-secondary' onClick={()=>setCollapsed(!collapsed)}>{ collapsed?'+':'-' }</button>
        <b>{props.title}</b>
        </div>
        <div style={{ display: collapsed?'none':'block' }} >{props.children}</div>
    </div>
}

function Editor(props: {node: string, path:Path, update: (key:Path, value:string)=>void}) {
    const rows = props.node.split('\n');
    const rowCount = rows.length;
    return <div className='editor-row'><div className='editor'>
        <textarea className="form-control" value={props.node} rows={rowCount} onChange={e => props.update(props.path, e.target.value)} />
    </div>
            <div className='preview' dangerouslySetInnerHTML={{__html: props.node}}
            contentEditable={true}></div></div>
}

function JsonNode(props: {node: TextNode, path:Path,
        update: (key:Path, value:string)=>void,
        add: (path:Path, key:string, value:string)=>void}) {
    const node = props.node;
    if (typeof node === 'string') {
        return <Editor node={node} path={props.path} update={props.update} />;
    } else    if (node instanceof Array) {
        return <ArrayNode node={node} path={props.path} update={props.update} add={props.add} />
    } else {
        return <ObjectNode node={node} path={props.path} update={props.update} add={props.add} />
    }
}

function ArrayNode(props: {node: TextNode[], path:Path,
    update: (key:Path, value:string)=>void,
    add: (path:Path, key:string, value:string)=>void}) {
        const texts = props.node.map((value, ix) =>
            <div key={ix}>
                <div><Collapsible title={ix.toString()}><JsonNode node={value} path={[...props.path, ix.toString()]} update={props.update} add={props.add} /></Collapsible></div></div>);
    return <div className='section'>{texts}</div>;
}

function ObjectNode(props: {node: DictionaryNode, path:Path,
        update: (key:Path, value:string)=>void,
        add: (path:Path, key:string, value:string)=>void,
}) {
    function addKey() {
        const key = window.prompt('Key name:');
        if (key !== null && key !== '') {
            props.add(props.path, key, '');
        }
    }
    const texts = Object.entries(props.node).map(([key, value]) =>
            <div key={key}>
                <div><Collapsible title={key}><JsonNode node={value} path={[...props.path, key]} add={props.add} update={props.update} /></Collapsible></div></div>);
    return <div className='section'>{texts}
                <div><button className='btn btn-outline-secondary' onClick={addKey}>Add key</button></div>
                </div>;
}

function Export(props: {data: TextNode}) {
    return <textarea value={JSON.stringify(props.data)} readOnly={true}></textarea>
}

function cloneNode(node: DictionaryNode) {
    return (Array.isArray(node) ? node.slice() : Object.assign({}, node)) as DictionaryNode;
}

function updateNode(node: DictionaryNode, path: Path, value: string) {
    const copy = cloneNode(node);
    const name = path.first();
    if (path.length===1) {
        copy[name] = value;
    } else {
        copy[name] = updateNode(copy[name] as DictionaryNode, path.slice(1), value);
    }
    return copy;
}

function modifyNode(node: DictionaryNode, path: Path, fun: (node: TextNode)=>void) {
    const copy = cloneNode(node);
    const name = path.first();
    if (path.length===0) {
        fun(copy);
    } else {
        copy[name] = modifyNode(copy[name] as DictionaryNode, path.slice(1), fun);
    }
    return copy;
}

function addNode(node: DictionaryNode, path: Path, key: string, value: string) {
    return modifyNode(node, path, node=>{
        (node as DictionaryNode)[key] = value;} );
}

const localStorageKey = 'EditorApp_en';
function save(node: TextNode) {
    window.localStorage.setItem(localStorageKey, JSON.stringify(node));
}
function load() {
    const data = window.localStorage.getItem(localStorageKey);
    if (data !== null) {
        return JSON.parse(data) as DictionaryNode;
    } else {
        return null;
    }
}
function loadFromFile() {
    return enTexts as unknown as DictionaryNode;
}
function init() {
    const data = load();
    if (data === null) {
        return loadFromFile();
    }
    return data;
}

export function TextManager() {
    const data = init();
    const [node, setNode] = useState(data);
    const update = (path: Path, value: string) => {
        console.log('update', path, value);
        setNode(n => {
            const n1 = updateNode(n, path, value);
            save(n1);
            return n1;
        });
    }
    function add(path: Path, key:string, value: string) {
        console.log('add', path, key, value);
        setNode(n => {
            const n1 = addNode(n, path, key, value);
            save(n1);
            return n1;
        });
    }
    function resetToFile() {
        setNode(_n => {
            const n1 = loadFromFile();
            save(n1);
            return n1;
        });
    }
    async function resetToServer() {
        const response = await fetch('/api/texts');
        const data = await response.json() as DictionaryNode;
        setNode(_n => {
            const n1 = data;
            save(n1);
            return n1;
        });
    }
    function saveOnServer() {
        fetch('/api/texts', {
            method:'put',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(node)}
        ).catch(error => {console.error('Error:', error)});
    }
    return (
        <div className='text-manager'>
            <button className='btn btn-outline-secondary' onClick={resetToFile}>Load from file (discards current)</button>
            <button className='btn btn-outline-secondary' onClick={() => void resetToServer()}>Load from server (discards current)</button>
            <JsonNode node={node} path={[]} update={update} add={add} />
            <Export data={node} />
            <button className='btn btn-outline-secondary' onClick={saveOnServer}>Save on server</button>
        </div>
    );
}

