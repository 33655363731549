import { useState, useContext, useEffect } from 'react';
import './InputConnectorComponent.css';
import './ConnectorComponent.css';
import { Pos } from '../../position';
import { toHex } from '../../../common/hex';
import { ConnectorLocation } from './ConnectorLocation';
import { CanvasContext, CanvasService, DiagramActions } from '../builder/DiagramBuilder';
import React from 'react';
import { NodeConnection } from '../connection/NodeConnection';
import { findParentDiagramNode, NodeLocation } from '../node/NodeComponent';
import { InputConnectorState } from 'diagram/connectorState';



/* Not a react component, but an association between a connector and the rendered html element */
export class InputConnectorLocation implements ConnectorLocation {
    constructor(readonly connector: InputConnectorState,
        readonly connectorElement: HTMLElement,
        readonly canvasService: CanvasService,
        readonly nodeComponent: NodeLocation) {  }
    get connectorIndex() { return this.connector.index; }
    get connection() { return this.connector.connection; }
    get canvasPos() {
        // position of connector point (relative to canvas), for drawing of arrow
        // offset relative to connector element
        const offset = new Pos(10, 16);
        return this.canvasService
            .getRelativePos(this.connectorElement)
            .addPos(offset);
    }
}

export function InputConnectorComponent(props: {
        isPalette: boolean;
        isFree: boolean;
		connector: InputConnectorState;
        selectedConnector: InputConnectorState | undefined;
        diagramActions: DiagramActions;
    }) {

	const [state, setState] = useState(()=>{
		return {
            inputConnectorComponent: undefined as InputConnectorLocation | undefined
        };
    });

    const elementRef = React.createRef<HTMLDivElement>();

    const canvasService = useContext(CanvasContext);

    useEffect(() => {
        const element = elementRef.current;
        let connectorRef: InputConnectorLocation | undefined = undefined;
        if (!props.isPalette && element) {
            connectorRef = new InputConnectorLocation(props.connector, element, canvasService,
                new NodeLocation(findParentDiagramNode(element), canvasService));
            canvasService.registerInputConnectorComponent(connectorRef);
            setState({...state, inputConnectorComponent: connectorRef});
        }
        return ()=>{
            if (connectorRef) {
                canvasService.unregisterInputConnectorComponent(connectorRef);
            }
        }
    }, []);

    const connectorClassName =  props.connector.name;

    const connectorState =  props.connector.state;
    const stateText = (connectorState === null) ? '?' : toHex(props.connector.numState, 1);

    const width =  props.connector.width;
    const oscillating =  props.connector.oscillating;
    const label =  props.connector.name;

    const isPalette =  props.isPalette;
    const isFree =  props.isFree;
    const stateVisible =  !isPalette && isFree && connectorState !== null;

	return (<div ref={elementRef} className={'input-connector connector ' + connectorClassName}>

    { stateVisible && (
        <div className='state'>{stateText}</div>)}

    <svg className={'svg-connector' + (oscillating ? ' oscillating' : '')}>

        <circle r='10' cx={10} cy={10} className={'connector-circle' + (connectorState ? ' active-state' : '')} />
        <text x='10' y='14' textAnchor='middle' className='connector-text'>
            {label}
        </text>

        { width > 1 && (
            <text x='0' y='25' textAnchor='middle' className={'width-indicator'}>{width}</text>)}

        { !isPalette && (
            <NodeConnection
                    connection={props.connector.connection}
                    inputConnectorComponent={state.inputConnectorComponent}
                    selectedConnector={props.selectedConnector}
                    inputConnector={props.connector}
                    diagramActions={props.diagramActions}
                    />
        )}
    </svg>
</div>
);
}




