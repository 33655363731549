import { bit, pins } from '../pins';
import { ComponentInternalState } from '../componentType';
import { BaseBuiltinComponentType } from './baseNodeType';
import { transparent } from './dependency';
import { ComponentInstanceState } from 'diagram/componentState';


export class ClockState implements ComponentInternalState {
    state = 0;
    constructor(private node: ComponentInstanceState) { }
    resolveOutputs(_node: ComponentInstanceState) {
        return [this.state];
    }
    reset() {
        this.state = 0;
    }
    flip() {
        this.state = 1 - this.state;
        this.node.stateChanged();
    }
    tick() {
        this.flip();
        this.flip();
    }
}

export const clockNodeType = new class extends BaseBuiltinComponentType {
    displayHint = 'clock';
    name = 'clock';
    key = 'CLOCK';
    inputs = [];
    outputs = pins(bit('cl'));
    hasInternalState = false;
    depends = transparent();
    createInternalState = (node: ComponentInstanceState) =>
        new ClockState(node);
};

