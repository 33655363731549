import { FunctionDefinition, Segment } from './instructions';
import { ErrorResult, Option } from '../compiler/shared';
import { LinkedUnits as LinkedUnit, LinkedUnits } from './vmParse';

class VmExeuctionError extends Error { }

export class StackFrame {
    programCounter = 0;
    readonly locals: number[];
    readonly stack: number[] = [];
    constructor(public readonly func: FunctionDefinition, public readonly args: number[]) {
        const localsCount = func.localsCount;
        this.locals = new Array<number>(localsCount).fill(0);
    }
}

interface Instruction {
    type: string;
    execute(engine: Engine): void | 'Exit';
}

export class Engine {
    readonly static: number[] = [];
    readonly temps: number[] = new Array<number>(8).fill(0);
    readonly callStack: StackFrame[] = [];
    executionError?: string;
    isFinished = false;

    constructor(public readonly code: LinkedUnit) {}

    getSegment(segment: Segment, index: number) {
        switch (segment) {
            case Segment.argument:
                return this.currentFrame.args.itemAt(index);
            case Segment.local:
                return this.currentFrame.locals.itemAt(index);
            case Segment.temp:
                return this.temps.itemAt(index);
            case Segment.static:
                // unassigned address default to 0
                return this.static[index] ?? 0;
            case Segment.constant:
                return index;
            default:
                // Internal error, since the parser should already have verified segments
                throw new Error('segment: ' + segment);
        }
    }

    setSegment(segment: Segment, index: number, value: number) {
        switch (segment) {
            case Segment.argument:
                this.currentFrame.args[index] = value;
                break;
            case Segment.local:
                this.currentFrame.locals[index] = value;
                break;
            case Segment.temp:
                this.temps[index] = value;
                break;
            case Segment.static:
                this.static[index] = value;
                break;
            default:
                // Internal error, since the parser should already have verified segments
                throw new Error('segment: ' + segment);
        }
    }
    run(entryFunctionName: string) {
        this.init(entryFunctionName);
        while (this.step()) { /* run til end */ }
        const returnValue = this.popStack();
        return returnValue;
    }
    init(entryFunctionName: string) {
        this.isFinished = false;
        this.executionError = undefined;
        this.callStack.length = 0;
        let fun = this.code.funcs.get('[toplevel]');
        if (!fun) {
            fun = this.code.funcs.get(entryFunctionName);
            if (!fun) {
                throw new ErrorResult(`Entry function '${entryFunctionName}' not found.`);
            }
        }
        this.callStack.push(new StackFrame(fun, []));
    }
    isFailed() {
        this.executionError !== undefined;
    }
    atEnd() {
        if (this.isFinished) {
            return true;
        }
        const frame = this.currentFrame;
        const instructions = frame.func.instructions;
        if (frame.programCounter >= instructions.length && this.atTopFrame) {
            this.isFinished = true;
            return true;
        }
        return false;
    }
    nextInstruction() {
        const frame = this.currentFrame;
        const instructions = frame.func.instructions;
        const instruction = instructions[frame.programCounter]!;
        if (!instruction) {
            console.log('NO INSTR', frame.programCounter, instructions)
        }
        return instruction;
    }
    nextLine() {
        if (this.atEnd()) {
            return undefined;
        }
        const instr = this.nextInstruction();
        return this.code.instructionToSyntax.get(instr);
    }
    nextLineDebug() {
        if (this.atEnd()) {
            return "At end";
        }
        const line = this.nextLine();
        if (!line) {
            return '(no line)';
        }
        return line.toDebugText();
    }
    step() {
        if (this.atEnd()) {
            this.isFinished = true;
            return false;
        }
        const instruction = this.nextInstruction()
        if (instruction.type.toLowerCase() === 'return' && this.atTopFrame) {
            this.isFinished = true;
            return false;
        }
        const status = this.execute1(instruction);
        if (status === 'Exit') {
            this.isFinished = true;
            return false;
        }
        return true;
    }
    reset() {
        this.init('.toplevel');
    }
    execute1(instruction: Instruction): void | 'Exit' {
        const currentPc = this.currentFrame.programCounter;
        // increment program counter *before* executing command
        // so 'goto' can override the new address
        this.currentFrame.programCounter++;
        try {
            return instruction.execute(this);
        } catch (e) {
            if (e instanceof VmExeuctionError) {
                // remain on the line that failed
                this.currentFrame.programCounter = currentPc;
                this.executionError = e.message;
                return;
            }
            throw e;
        }
    }

    get currentFrame() {
        return this.callStack.at(-1)!;
    }
    get atTopFrame() {
        return this.callStack.length === 1;
    }

    popStack() {
        if (this.currentFrame.stack.length === 0) {
            throw new VmExeuctionError('Stack is empty');
        }
        return this.currentFrame.stack.pop()!;
    }
    pushStack(val: number) {
        if (val === undefined) {
            throw Error('Undefined value');
        }
        this.currentFrame.stack.push(val);
    }
    initStack() {

    }
}

export class RunResult {
    constructor(public result: number, public engine: Engine) {}
}


// TODO: move to engine, call runMain
export function runFunction(unit: LinkedUnits, functionName: string): Option<RunResult> {
    try {
        const engine = new Engine(unit);
        const returnValue = engine.run(functionName);
        return new RunResult(returnValue, engine);
    } catch (e) {
        if (e instanceof ErrorResult) {
            return e;
        }
        console.error(e);
        return new ErrorResult(`Internal execution error: ${(e as Error).message} `);
    }
}

// TODO: move to engine, call runMain
export function runMain(unit: LinkedUnits): Option<RunResult> {
    return runFunction(unit, 'main');
}
