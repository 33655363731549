import { Task } from '../app/task';
import { optionalMissionsCategory, diagramMission } from '../app/optionalTrack';
import {
    inputOutputMission,
    arithmeticAluMission, logicAluMission, alu2Mission, conditionMission,
    combinedStateMission,
    ramMission,
    flipFlopMission, dff2Mission, counterMission,
    demuxMission, invMission, andMission, orMission, xorMission, selectorMission,
    addMission, addMultiMission, subMission, addcMission, incMission,
    isZero4Mission, signMission, nandMission,
    aluInstructionMission, controlUnitMission, controlSelectorMission
} from 'diagram/missions';
import { getSoftwareTrack } from './softwareTrack';
import { cpu3MissionTask } from 'diagram/missions/cpuMission';
import { latchMission, srLatchMission } from 'diagram/missions/latchMission';


export type MissionEpic = {
    readonly name: string;
    readonly primaryTrack?: boolean;
    readonly groups: readonly MissionGroup[];
};

export type MissionGroup = {
    readonly name: string;
    readonly missions: readonly Task[];
};

export const getMissions = (): MissionEpic[] =>
    [
        {
            name: 'Hardware',
            groups: getPrimaryTrack()
        },
        {
            name: 'Software',
            groups: getSoftwareTrack()
        },
        optionalMissionsCategory
    ];


const getPrimaryTrack = (): MissionGroup[] =>
    [
        {
            name: 'Logic Gates',
            missions: [
                diagramMission(nandMission),
                diagramMission(invMission),
                diagramMission(andMission),
                diagramMission(orMission),
                diagramMission(xorMission),
            ]
        },
        {
            name: 'Arithmetics',
            missions: [
                diagramMission(addMission),
                diagramMission(addcMission),
                diagramMission(addMultiMission),
                diagramMission(incMission),
                diagramMission(subMission),
                diagramMission(isZero4Mission),
                diagramMission(signMission),
            ]
        },
        {
            name: 'Switching',
            missions: [
                diagramMission(selectorMission),
                diagramMission(demuxMission),
            ]
        },
        {
            name: 'Arithmetic Logic Unit',
            missions: [
                diagramMission(logicAluMission),
                diagramMission(arithmeticAluMission),
                diagramMission(alu2Mission),
                diagramMission(conditionMission),
            ]
        },
        {
            name: 'Memory',
            missions: [
                diagramMission(srLatchMission),
                diagramMission(latchMission),
                diagramMission(flipFlopMission),
                diagramMission(dff2Mission),
                diagramMission(counterMission),
                diagramMission(ramMission),
            ]
        },
        {
            name: 'Processor',
            missions: [
                diagramMission(combinedStateMission),
                diagramMission(aluInstructionMission),
                diagramMission(controlSelectorMission),
                diagramMission(controlUnitMission),
                cpu3MissionTask,
                diagramMission(inputOutputMission),
            ]
        },
    ];

