import { VerificationError, VerificationOk } from '../../app/verificationResults';
import { ComponentInstance } from '../circuitStructure';
import { bit, ConstantPin, PinGroup, pins } from '../pins';
import { ConnectorState, InputConnectorState } from '../connectorState';
import { DiagramType } from '../diagramMissionType';
import { binaryTest, TestCase, VerificationSubjectAdapter } from '../verification';
import { BaseBuiltinComponentType } from 'diagram/missions/baseNodeType';
import { diagram, DiagramAdapter, Truth } from 'diagram/missions/missions';
import { atomic, transparent } from 'diagram/missions/dependency';
import { ComponentInstanceState } from 'diagram/componentState';


export interface TrinaryRule {
    resolve(node: ComponentInstanceState): ConnectorState[];
    resolve1(inp: ConnectorState[]): ConnectorState[];
}

/* Similar to a binary output rule, but if gate is closed, the output is undefined, not zero */
export class OutputRuleTrinary implements TrinaryRule {
    constructor(readonly rule: ((source: ConnectorState, gate: ConnectorState) => ConnectorState)) { }
    resolve(node: ComponentInstanceState) {
        const a = node.inputConnectorStates.itemAt(0).state;
        const b = node.inputConnectorStates.itemAt(1).state;
        const result = this.rule(a, b);
        return [result];
    }
    resolve1(inp: ConnectorState[]) {
        const result = this.rule(inp.itemAt(0), inp.itemAt(1));
        return [result];
    }
}


// Same as StatelessComponent except we support trinary output
class CmosTransistorType extends BaseBuiltinComponentType {
    constructor(public readonly name: string,
        public readonly key: string,
        public readonly inputs: PinGroup[],
        public readonly outputs: PinGroup[],
        public readonly rule: TrinaryRule) { super(); }
    diagramType = DiagramType.TransistorLevel;
    depends = atomic();
    createInternalState = () => ({
        resolveOutputs: (node: ComponentInstanceState): ConnectorState[] =>
            this.rule.resolve(node),
        reset: () => { /* no state */ }
    });
}

/* PMOS: 1 on base will close, 0 open */
export const pmosTransistorType = new CmosTransistorType('pmos',
    'PMOS',
    pins(bit('off'), bit('i')),
    pins(bit('o')),
    new OutputRuleTrinary((gate, source) => gate === 1 ? null : source)
);


/* NMOS: 1 on base will open, 0 will close. Weak 0 when closed.  */
export const nmosTransistorType = new CmosTransistorType('nmos',
    'NMOS',
    pins(bit('on'), bit('i')),
    pins(bit('o')),
    new OutputRuleTrinary((gate, source) => gate === 1 ? source : null)
);


class CmosWireJunctionInternalState {
    resolveOutputs(node: ComponentInstanceState): ConnectorState[] {
        const a = node.inputConnectorStates.itemAt(0).state;
        const b = node.inputConnectorStates.itemAt(1).state;
        if ((a === 1 && b === 0) || (a === 0 && b === 1)) {
            // short circuit!
            // TODO: mark short circuit path
            node.markError('ShortCircuit');
            this.markShort(node.inputConnectorStates.itemAt(0));
            this.markShort(node.inputConnectorStates.itemAt(1));

            return [null];
        }
        return [this.resolve(a, b)];
    }
    resolve(a: ConnectorState, b: ConnectorState) {
        return a === null ? b : b === null ? a : a || b;
    }
    /* mark short circuit */
    markShort(connector: InputConnectorState) {
        connector.oscillating = true;
        if (connector.connection) {
            //const outputConnector = connector.connection;
        }
    }
    reset() { }
}

// combine two wires in an output
export const cmosWireJunctionNodeType = new class extends BaseBuiltinComponentType {
    name = 'junction';
    key = 'TRINARY_LOGIC_WIRE_JUNCTION';
    displayHint = 'wireJunction';
    inputs = pins(bit(''), bit(''));
    outputs = pins(bit(''));
    depends = transparent();
    diagramType = DiagramType.TransistorLevel;
    createInternalState = () => new CmosWireJunctionInternalState();
};


/* Tests:
 At the transistor level, all connectors should be connected.
 * */
class ConnectionsTest implements TestCase {
    verify(adapter: VerificationSubjectAdapter) {
        const hasUnconnecedPin = (node: ComponentInstance) =>
            node.inputConnectors.some(i => i.connection === undefined) ||
            node.outputConnectors.some(i => i.connections.length === 0);

        const d = (adapter as DiagramAdapter).diagram;
        for (const node of d.nodes) {
            if (node.nodeType === nmosTransistorType || node.nodeType === pmosTransistorType) {
                if (hasUnconnecedPin(node.componentInstance)) {
                    return new VerificationError('All tree pins of all transistors must be connected');
                }
            }
        }
        return new VerificationOk();
    }
}

/* CMOS Nand - requires complementary nmos and pmos transistors */
export const cmosNandMission = diagram({
    key: 'CMOS_NAND',
    unlock: true,
    diagramType: DiagramType.TransistorLevel,
    inputPins: [bit('a'), bit('b'), new ConstantPin('v', 1), new ConstantPin('g', 0)],
    outputPins: [bit('')],
    palette: [nmosTransistorType, pmosTransistorType, cmosWireJunctionNodeType],
    // we cant use the truth-table mechanism, since we also have the drain/sink pins
    tests: [
        new ConnectionsTest(),
        // nand: a, b, drain (always 1), sink (always 0)
        binaryTest([false, false, true, false], [true]),
        binaryTest([false, true, true, false], [true]),
        binaryTest([true, false, true, false], [true]),
        binaryTest([true, true, true, false], [false]),
    ],
    truth: [
        [[false, false, true, false], [true]],
        [[false, true, true, false], [true]],
        [[true, false, true, false], [true]],
        [[true, true, true, false], [false]],
    ] as Truth,
    score: { min: 4 }
} as const);

/* CMOS Inverter */
export const cmosInvMission = diagram({
    key: 'CMOS_INV',
    unlock: true,
    diagramType: DiagramType.TransistorLevel,
    inputPins: [bit('a'), new ConstantPin('v', 1), new ConstantPin('g', 0)],
    outputPins: [bit('')],
    palette: [nmosTransistorType, pmosTransistorType, cmosWireJunctionNodeType],
    // we cant use the truth-table mechanism, since we also have the drain/sink pins
    tests: [
        new ConnectionsTest(),
        // Not: a, drain (always 1), sink (always 0)
        binaryTest([false, true, false], [true]),
        binaryTest([true, true, false], [false]),
    ],
    truth: [
        [[false, true, false], [true]],
        [[true, true, false], [false]],
    ] as Truth,
    score: { min: 2 }
} as const);

export const cmosNorMission = diagram({
    key: 'CMOS_NOR',
    unlock: true,
    diagramType: DiagramType.TransistorLevel,
    inputPins: [bit('a'), bit('b'), new ConstantPin('v', 1), new ConstantPin('g', 0)],
    outputPins: [bit('')],
    palette: [nmosTransistorType, pmosTransistorType, cmosWireJunctionNodeType],
    // we cant use the truth-table mechanism, since we also have the drain/sink pins
    tests: [
        new ConnectionsTest(),
        // nand: a, b, drain (always 1), sink (always 0)
        binaryTest([false, false, true, false], [true]),
        binaryTest([false, true, true, false], [false]),
        binaryTest([true, false, true, false], [false]),
        binaryTest([true, true, true, false], [false]),
    ],
    truth: [
        [[false, false, true, false], [true]],
        [[false, true, true, false], [false]],
        [[true, false, true, false], [false]],
        [[true, true, true, false], [false]],
    ] as Truth,
    score: { min: 4 }
} as const);


