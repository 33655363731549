import {
    nandNodeType, invNodeType, andNodeType, orNodeType, xorNodeType, inv16NodeType, and16NodeType,
    bundlerNodeType,
    selectorNodeType,
    decoderNodeType,
    selector16NodeType,
    demuxNodeType,
    splitterNodeType,
    or16NodeType,
    xor16NodeType
} from 'diagram/missions/logicMissions';
import {
    addNodeType, addcNodeType, add16NodeType, add16cNodeType, incNodeType, sub16NodeType,
    zeroNodeType, zero16NodeType, isZero16NodeType, isNegNodeType
} from 'diagram/missions/arithmeticMissions';
import { buttonNodeType, lampNodeType } from './inputOutputMission';
import { controlUnitNodeType } from './controlUnitMission';
import { controlSelectorNodeType } from './controlSelectorMission';
import { clockNodeType } from './clockNodeType';
import { romNodeType } from './romNodeType';
import { aluNodeType, aluUnaryModifierNodeType, conditionNodeType } from './aluMissions';
import { alu2NodeType, arithmeticUnitNodeType, logicUnitNodeType, condition2NodeType, aluInstructionNodeType } from './alu2missions';
import { cpuState2NodeType } from './combinedStateMission';
import { ram2NodeType, ram64kbNodeType } from './ramMissions';
import { dffNodeType } from './dffMissions';
import { dff16NodeType } from './registerMission';
import { counterNodeType } from './counterMission';
import { BuiltinComponentType } from '../componentType';
import { barrelShlNodeType, barrelShr22NodeType, barrelShrNodeType, maxNodeType, shlNodeType, shrNodeType } from '../optionalMissions/optionalMissions';
import { cmosWireJunctionNodeType, nmosTransistorType, pmosTransistorType } from '../optionalMissions/transistorLevel';
import { relayOffType, relayOnType } from './nandMission';
import { bitSelectNodeType, constNodeType } from './constNodeType';
import { mul22NodeType, floatNormalizeOverflowNodeType, packFloatNodeType, unpackFloatNodeType, floatMulUnpackedNodeType, floatVerifyExponentNodeType, floatNormalizeUnderflowNodeType, floatAlignNodeType, floatAddSignedMagnitudeNodeType, splitter22NodeType } from '../optionalMissions/floatMission';
import { mappedMemoryNodeType, csPcNodeType, csRegisterNodeType, csRegistersNodeType, csTriggerNodeType, gpMemoryBlockNodeType, csControlUnitNodeType, bundler18NodeType, csModeNodeType } from '../optionalMissions/multitasking';
import { latchNodeType, srLatchNodeType } from './latchMission';
import { ledBNodeType, ledGNodeType, ledRNodeType } from './displayMemoryMission';

/* All node types need to be registered here to be located when restoring diagrams */
export const nodeTypes = {
    nand: nandNodeType,
    inv: invNodeType,
    and: andNodeType,
    or: orNodeType,
    xor: xorNodeType,
    inv16: inv16NodeType,
    and16: and16NodeType,
    or16: or16NodeType,
    xor16: xor16NodeType,
    add: addNodeType,
    addc: addcNodeType,
    add16: add16NodeType,
    add16c: add16cNodeType,
    inc: incNodeType,
    sub16: sub16NodeType,
    zero: zeroNodeType,
    zero16: zero16NodeType,
    isZero: isZero16NodeType,
    isNeg: isNegNodeType,
    splitter: splitterNodeType,
    bundler: bundlerNodeType,
    selector: selectorNodeType,
    decoder: decoderNodeType,
    selector16: selector16NodeType,
    demux: demuxNodeType,
    logicUnit: logicUnitNodeType,
    arithmeticUnit: arithmeticUnitNodeType,
    alu: aluNodeType,
    aluUnaryModifier: aluUnaryModifierNodeType,
    alu2: alu2NodeType,
    condition: conditionNodeType,
    condition2: condition2NodeType,
    srLatch: srLatchNodeType,
    latch: latchNodeType,
    dff: dffNodeType,
    dff16: dff16NodeType,
    ram2: ram2NodeType,
    counter: counterNodeType,
    ram64kb: ram64kbNodeType,
    ledRNodeType,
    ledGNodeType,
    ledBNodeType,
    cpuState2: cpuState2NodeType,
    rom: romNodeType,
    aluInstruction: aluInstructionNodeType,
    controlSelector: controlSelectorNodeType,
    controlUnit: controlUnitNodeType,
    lamp: lampNodeType,
    button: buttonNodeType,
    clock: clockNodeType,
    // from optional levels:
    const: constNodeType,
    bitSelect: bitSelectNodeType,
    shl: shlNodeType,
    shr: shrNodeType,
    barrelShlNodeType,
    barrelShr22NodeType,
    barrelShrNodeType,
    max: maxNodeType,
    floatMulUnpacked: floatMulUnpackedNodeType,
    floatNormalizeOverflow: floatNormalizeOverflowNodeType,
    floatNormalizeUnderflow: floatNormalizeUnderflowNodeType,
    floatVerifyExponent: floatVerifyExponentNodeType,
    floatAlign: floatAlignNodeType,
    floatAddSignedMagnitude: floatAddSignedMagnitudeNodeType,
    packFloat: packFloatNodeType,
    unpackFloat: unpackFloatNodeType,
    mul22NodeType,
    splitter22NodeType,
    // multitasking
    csTriggerNodeType,
    csModeNodeType,
    csRegisterNodeType,
    csPcNodeType,
    csRegistersNodeType,
    gpMemoryBlockNodeType,
    bundler18NodeType,
    mappedMemoryNodeType,
    csControlUnitNodeType,
    // from transistor level:
    pmos: pmosTransistorType,
    nmos: nmosTransistorType,
    cmosWireJunction: cmosWireJunctionNodeType,
    relayOn: relayOnType,
    relayOff: relayOffType
} as const;

type NodeTypeLookup = { [k: string]: BuiltinComponentType };
export const componentTypes = {
    /** Array of all node types */
    getAll() {
        const all: NodeTypeLookup = nodeTypes;
        return Object.values(all);
    },
    /**  A map from NodeType keys to NodeType object.
     *   Used by deserializer.
     */
    getNodeTypeMap() {
        return new Map(componentTypes.getAll().map(nodeType => [nodeType.key, nodeType]));
    }
}

