import './edge-group.css';
import { OutputNodeComponent } from './OutputNodeComponent';
import { OutputStateGroup } from '../../circuitState';
import { ComponentBuilder } from '../../componentBuilder';
import { DiagramActions } from '../builder/DiagramBuilder';
import { InputConnectorState } from 'diagram/connectorState';

export function OutputGroupComponent(props: {
		group: OutputStateGroup;
		model: ComponentBuilder;
        selectedConnector: InputConnectorState | undefined
        diagramActions: DiagramActions;
    }) {

    const outputNodes =  props.group.nodes;
    const multi =  props.group.nodes.length > 1;
    const hasLabel =  props.group.label !== undefined;
    const label =  props.group.label;
	const model = props.model;

    const pins = (outputNodes).map((connector, ix) => (
        <OutputNodeComponent key={ix} connector={connector} model={model}
            selectedConnector={props.selectedConnector}
            diagramActions={props.diagramActions}
            />))
    if (multi) {
        return (<div className='diagram-output-group multi'>
            { hasLabel && <div className='diagram-group-label'>{label}</div> }
            <div className='component-row'>{pins}</div>
        </div>);
    } else {
        return (<div className='diagram-output-group'>
            <div className='component-row'>{pins}</div>
        </div>);
    }
}

