
import { } from './extensions';

export type Boolean16 = readonly [
    boolean, boolean, boolean, boolean,
    boolean, boolean, boolean, boolean,
    boolean, boolean, boolean, boolean,
    boolean, boolean, boolean, boolean,
];
export type DenseArray = readonly [
    number, number, number, number, number, number, number, number,
    number, number, number, number, number, number, number, number,
    ...number[]
];

/** Removes an item in from an array. (Only first removed if duplicates) */
export function deleteItem<T>(array: T[], item: T): void {
    const ix = array.indexOf(item);
    if (ix > -1) {
        array.splice(ix, 1);
    }
}

export function insertAt<T>(array: T[], index: number, item: T): void {
    array.splice(index, 0, item);
}

/** Removes the first item in the array which satisfies the predicate. Returns the removed item */
export function removeWhere<T>(array: T[], predicate: (item: T) => boolean): T | undefined {
    const ix = array.findIndex(predicate);
    if (ix === -1) {
        return undefined;
    }
    const item = array[ix];
    array.splice(ix, 1);
    return item;
}

export function permutations<T>(poss: T[][]): T[][] {
    if (poss.length === 0) {
        return [[]];
    }
    const item = poss.first();
    const rest = permutations(poss.slice(1));
    return item.flatMap(it => rest.map(r => [it].concat(r)));
}

/**
 * pairwise values from array, e.g. [red, green, blue] --> [[red,green], [green,blue]]
 */
export function pairs<T>(arr: T[]): [T, T][] {
    const result: [T, T][] = [];
    for (let ix = 0; ix < arr.length - 1; ix++) {
        result.push([arr.itemAt(ix), arr[ix + 1]!]);
    }
    return result;
}


function throwError(message: string): never {
    throw new Error(message);
}
