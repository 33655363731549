
export type HalfPrecisionFloatingPoint = number;
export type Bit = 0 | 1;
export type UnpackedFloat = [Bit, number, number];
export type SignedMagnitude = [Bit, number];

/**
 *
 * Floating-point operations using 16-bit 'half-precision' values.
 * (For use as reference when verifying floatng-point circuits.)
 *
 * bit 15 is sign
 * bit 14-10 is 5 bit exponent (zero offset is 15, ie. 1 would have exponent 15)
 * bit 9-0 is 10 bit signifiand
 *
 * Exponent 00000 means the number is 0
 * We do not currently support sub-normal numbers (except 0).
 * Exponent 00000 and 11111 are reserved and not currently used.
 *
 * Significand has an implicit prefixed 1, ie. it has 11 effective bits of precision.
 *
 */



/**
 * number is turned into 10-bit significant with implicit leading 1
 * offset is how many bits have been adjusted
 * e.g. if input is 11-digit, offset is 0
 * if input is 12-digit, offset is 1
 * if input is 9 digit, offset is -2
 */
export function normalizeSignificand(fp: number): [number, number] {
    const magnitude = Math.floor(Math.log2(fp));
    const offset = magnitude - 10;
    // convert to 11-bit number
    if (magnitude < 11) {
        fp = fp << -offset;
    }
    else if (magnitude > 11) {
        fp = fp >> offset;
    }
    // 10 bits
    const normalizedSignificand = fp & 0b11_1111_1111;
    return [offset, normalizedSignificand];
}
export function normalizeAndPack(sign: Bit, exp: number, significand: number) {
    if (significand === 0) {
        return pack(sign, 0, 0);
    }
    const [offset, normalizedSignificand] = normalizeSignificand(significand);
    const normalizedExponent = exp + offset;
    return pack(sign, normalizedExponent, normalizedSignificand);
}

export function normalizeOverflow(exponent: number, significand: number) {
    const magnitude = Math.floor(Math.log2(significand));
    const offset = magnitude - 10;
    // convert to 11-bit number
    if (magnitude > 11) {
        significand = significand >> offset;
    }
    return [exponent, significand];
}
export function normalizeUnderflow(exponent: number, significand: number) {
    if (exponent === 0) {
        return [0, 0];
    }
    const magnitude = Math.floor(Math.log2(significand));
    const offset = magnitude - 10;
    // convert to 11-bit number
    if (magnitude < 11) {
        significand = significand << -offset;
        exponent = exponent + offset;
    }
    return [exponent, significand];
}
export function verifyExponent(exponent: number, significand: number) {
    if (exponent > 31 || exponent < 0) {
        return [0b11111, significand];
    }
    return [exponent, significand];
}

/** align two numbers to the same exponent (the highest)
 * by prefixing and shifting the significand */
export function align(a: readonly [number, number], b: readonly [number, number])  {
    const [a_exponent, a_significand] = a;
    const [b_exponent, b_significand] = b;
    const maxExponent = Math.max(a_exponent, b_exponent);
    const a_significandAligned = a_significand >> (maxExponent - a_exponent);
    const b_significandAligned = b_significand >> (maxExponent - b_exponent);
    return [maxExponent, a_significandAligned, b_significandAligned] as const;
}
/**
 * converts a normalized signifcand into a regular integer by prefixing with 1
 * (If exponent is 00000 the significand is 0 or subnormal, so we do not prefix)
 */
function prefix(exponent: number, normalizedSignificand: number) {
    if (exponent === 0b00000) {
        return normalizedSignificand;
    } else {
        return 0b1_00_0000_0000 | normalizedSignificand;
    }
}
export function sub(a: HalfPrecisionFloatingPoint, b: HalfPrecisionFloatingPoint): HalfPrecisionFloatingPoint {
    return addSub(1, a, b);
}
export function add(a: HalfPrecisionFloatingPoint, b: HalfPrecisionFloatingPoint): HalfPrecisionFloatingPoint {
    return addSub(0, a, b);
}

/**
 * Convert a javascript number (positive or negative) to sign-magnitude representation.
 */
export function jsToSignedMagnitude(n: number): SignedMagnitude {
    const magnitude = Math.abs(n) & 0xFFFF;
    const sign = Math.sign(n) === -1 ? 1 : 0;
    return [sign as Bit, magnitude];
}

function signedMagnitudeToJs(sgn: number, magnitude: number) {
    return sgn === 1 ? -magnitude : magnitude;
}

export function addSignedMagnitudes(op: number, a: [number, number], b: [number, number]): SignedMagnitude {
    const [aSgn, aNum] = a;
    const [bSgn, bNum] = b;
    const aJs = signedMagnitudeToJs(aSgn, aNum);
    const bJs = signedMagnitudeToJs(bSgn, bNum);
    const result = op===0 ? aJs + bJs : aJs - bJs;
    const [rSign, rSignificand] = jsToSignedMagnitude(result);
    return [rSign, rSignificand];
}

export function addSub(op: number, a: HalfPrecisionFloatingPoint, b: HalfPrecisionFloatingPoint): HalfPrecisionFloatingPoint {
    const [a_sgn, a_exponent, a_significand] = unpack(a);
    const [b_sgn, b_exponent, b_significand] = unpack(b);
    const [exp, a_significandAligned, b_significandAligned] = align([a_exponent, a_significand], [b_exponent, b_significand]);
    const [sum_sign, sum_significand] = addSignedMagnitudes(op, [a_sgn, a_significandAligned], [b_sgn, b_significandAligned]);
    return normalizeAndPack(sum_sign, exp, sum_significand);
}


export function split(halfPrecision: number): [Bit, number, number] {
    const sgn = (halfPrecision >> 15) & 1;
    const exp = (halfPrecision >> 10) & 0b11111;
    const sig = halfPrecision & 0b11111_11111;
    return [sgn as Bit, exp, sig];
}

export function unpack(halfPrecision: number): [Bit, number, number] {
    const [signBit, exp, significand] = split(halfPrecision);
    return [signBit, exp, prefix(exp, significand)];
}

export function pack(signBit: Bit, exponent: number, significand: number) {
    if (exponent > 0b11110) {
        exponent = 0b11111;
        significand = 0;
    }
    return (signBit << 15) | (exponent << 10) | significand;
}

export function mulUnpacked(a: UnpackedFloat, b: UnpackedFloat): UnpackedFloat {
    const [a_sgn, a_exp, a_significand] = a;
    const [b_sgn, b_exp, b_significand] = b;

    const adjustedSumOfExponents = a_exp + b_exp - 15;

    // use 22 bit mul
    // 11 bit mul, result is 21 or 22 bits.
    // shl 10, result is 11 or 12 bits;
    const productOfSignificands = (a_significand * b_significand) >> 10;

    const sign = (a_sgn === b_sgn) ? 0 : 1;

    return [sign, adjustedSumOfExponents, productOfSignificands];
}

export function mul(a: HalfPrecisionFloatingPoint, b: HalfPrecisionFloatingPoint): HalfPrecisionFloatingPoint {
    const aUnpacked = unpack(a);
    const bUnpacked = unpack(b);
    const [sign, exponent, significand] = mulUnpacked(aUnpacked, bUnpacked);

    let [exponent1, significand1] = [exponent, significand];
    const significandoverflow = (significand1 & (1 << 12)) === 1;
    if (significandoverflow) {
        significand1 = significand1 << 1;
        exponent1++;
    }
    if (significand1 === 0) {
        exponent1 = 0;
    }
    // TODO: Check for exponent overflow ?

    const normalizedSignificand = significand1 & 0b11111_11111;

    return pack(sign, exponent1, normalizedSignificand);
}

/* utilities for testing */
export function toJs(half: number): number {
    const [signBit, exp, signif] = unpack(half);
    const signFactor = signBit === 0 ? 1 : -1;
    if (prefix(exp, signif) === 0) {
        return 0;
    }
    const val = signFactor * prefix(exp, signif) * Math.pow(2, exp - 15 - 10);
    return val;
}

/* convert a JS float into a 16-bit half precsion */
export function fromJs(x: number): HalfPrecisionFloatingPoint {
    const signBit = Math.sign(x) === -1 ? 1 : 0 as Bit;
    const num = Math.abs(x);
    if (num === 0) {
        // TODO: support subnormal (numbers < 2**-14)
        return pack(signBit, 0, 0);
    }
    // convert significand to 11-bit number
    const magnitude = Math.floor(Math.log2(num));
    const significand = num * (2 ** (10 - magnitude));
    return normalizeAndPack(signBit, 15 + magnitude, Math.floor(significand));
}
