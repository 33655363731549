import { depends } from './dependency';
import { word, bit, pins } from '../pins';
import { DelegateStateView } from '../stateViews';
import { dff2Mission } from './dffMissions';
import { ComponentInternalState } from '../componentType';
import { BaseBuiltinComponentType } from './baseNodeType';
import { ComponentInstanceState } from 'diagram/componentState';


export class RegisterState implements ComponentInternalState {
    output = 0;
    nextState = 0;
    readonly stateView = new DelegateStateView(() => this.output, 'Word');
    resolveOutputs(node: ComponentInstanceState) {
        const st = node.inputConnectorStates.itemAt(0).bitState;
        const data = node.inputConnectorStates.itemAt(1).numState;
        const clock = node.inputConnectorStates.itemAt(2).bitState;
        return this.resolve(st, data, clock);
    }
    resolve(st: boolean, data: number, clock: boolean) {
        if (clock) {
            if (st) {
                this.nextState = data;
            }
        } else {
            this.output = this.nextState;
        }
        const out = this.output;
        return [out] as const;
    }

    reset() {
        this.output = 0;
        this.nextState = 0;
    }
}

export const dff16NodeType = new class extends BaseBuiltinComponentType {
    readonly name = 'register';
    readonly key = 'DFF16';
    readonly inputs = pins(bit('st'), word('X'), bit('cl'));
    readonly outputs = pins(word());
    readonly hasInternalState = true;
    readonly depends = depends(dff2Mission, 8, false);
    readonly createInternalState = () => new RegisterState();
};
