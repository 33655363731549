import './Palette.css';
import { TrashComponent } from '../trash/TrashComponent';
import { NodeComponent } from '../node/NodeComponent';
import { createPaletteNode, PaletteComponentInstance } from '../../circuitState';
import { ComponentBuilder } from '../../componentBuilder';
import { Pos } from '../../position';
import { ComponentType } from '../../componentType';
import { DiagramActions } from './DiagramBuilder';


function getNodeLabel(nodeType: ComponentType) {
    if (!nodeType.displayHint) {
        return null;
    }
    const labels: Record<string, string> = {
        'lamp': 'lamp',
        'button': 'button',
        'wireJunction': 'junction',
        'relay-on': 'relay (default on)',
        'relay-off': 'relay (default off)',
        'splitter': '16 bit splitter',
        'bundler': '16 bit bundler',
    };
    const label = labels[nodeType.displayHint];
    if (label) {
        return  <div className='palette-label'>{label}</div>;
    }
    return null;
}

export function Palette(props: {
		model: ComponentBuilder;
        addNode: (nodeType: ComponentType, canvasPos: Pos)=>void;
}) {

    function getPaletteNodes() {
        const paletteOptions = props.model.palette;
        const palette: PaletteComponentInstance[][] = [];
        for (const type of paletteOptions) {
            // create two nodes stacked
            // so we can drag the top one to the canvas (will be returned when dropped)
            palette.push([
                createPaletteNode(type),
                createPaletteNode(type)]);
        }
        return palette;
    }

	const paletteNodes = getPaletteNodes();

    /* create a dummy handler, since palette nodes does not have any abilities except 'addNode' */
    const diagramActions: DiagramActions = {
        addNode: (nodeType: ComponentType, canvasPos: Pos) => {
            props.addNode(nodeType, canvasPos);
        },
        deleteOutputPin: () => {},
        updateOutputPinLabel: () => {},
        deleteInputPin: () => {},
        updateInputPinLabel: () => {},
        connect: () => {},
        clickConnectStart: () => {},
        cancelConnectMode: () => {},
        disconnect: () => {},
        stateChanged: () => {},
        deleteNode: () => {},
        expand: () => {},
        diagramStateChanged: () => {}
    };

	return (<div className='sidebar' id='palette'>
    <div id='temp-nodes'></div>

    <div className='palette-header-box'>
        <div className='palette-header'>Toolbox</div>
    </div>

    <div className='palette'>
        {(paletteNodes).map(nodeSet => (<>
            <div key={nodeSet.itemAt(1).nodeType.key} className='palette-nodetype'>

            { getNodeLabel(nodeSet.itemAt(1).nodeType) }
            
            <div className='palette-slot'>
                <NodeComponent node={nodeSet.first()} isPalette
                    selectedConnector={undefined}
                    diagramActions={diagramActions}
                    />
                <div className='draggable-overlay'>
                    <NodeComponent node={nodeSet.itemAt(1)} isPalette
                        selectedConnector={undefined}
                        diagramActions={diagramActions}
                    />
                </div>
            </div>

            

        </div></>))}
        </div>


    <div className='trash-panel'>
        <TrashComponent />
    </div>

</div>);
}

