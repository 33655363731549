import { DummySourceUnit } from 'common/location';
import { SyntaxNode } from './parse';
import { ErrorResult, Option } from './shared';
import { parseSubstitution } from './SyntaxRules';
import { } from '../common/extensions';

export type CodeGenerationRule = { production: string; code: string };

export class CodeSegment {
    constructor(public node: SyntaxNode, public rule: CodeGenerationRule | null, public code: string) {}
}

/* VM Code generated through rules.
    Each segment is generated by a particular rule.
    Tokens cannot cross segments */
export class GeneratedVmCode {
    // TODO: Support source unit pr segment, to allow tracking back to codegen templates
    unit = new DummySourceUnit();
    constructor(public segments: CodeSegment[]) {}
    asString() {
        return this.segments.map(s => s.code).join('');
    }
}

export function generateCode(root: SyntaxNode, codegen: CodeGenerationRule[]): Option<GeneratedVmCode> {
    try {
        const g = new CodeGenerator(root, codegen);
        if (g.code.every(segment => segment.code === '')) {
            return new ErrorResult(`No code was generated.`);
        }
        return new GeneratedVmCode(g.code);
    } catch (e) {
        if (e instanceof ErrorResult) {
            return e;
        }
        console.error(e);
        return new ErrorResult(`Internal code generator error: ${(e as Error).message} `);
    }
}

class CodeGenerator {
    code: CodeSegment[] = [];
    map: Map<string, CodeGenerationRule>;
    normalizeRule(prod: string) {
        return prod.trim().split(/\s+/).join(' ');
    }
    constructor(public root: SyntaxNode, public codegen: CodeGenerationRule[]) {
        // map from production to codegen
        // normalize rule, since it is entered by user and could have superflous whitespace
        this.map = new Map(codegen.map(rule => [this.normalizeRule(rule.production), rule]));
        this.generateCodeNode(this.root);
    }
    productionFor(node: SyntaxNode) {
        return node.type + ' -> ' + node.children.map(c => c.type).join(' ');
    }
    append(node: SyntaxNode, rule: CodeGenerationRule | null, code: string) {
        this.code.push(new CodeSegment(node, rule, code));
    }
    resolve(macro: string, node: SyntaxNode) {
        const parsed = parseSubstitution(macro);
        if (!parsed) {
            // should not happen since rule is validated
            throw new Error();
        }
        const [name, index] = parsed;
        const namedSet = node.children.filter(c => c.type === name);
        if (index) {
            if (namedSet.length < index) {
                // should not happen since rule is validated
                throw new Error();
            }
            return namedSet[index - 1];
        } else {
            if (namedSet.length !== 1) {
                // should not happen since rule is validated
                throw new Error();
            }
            return namedSet[0];
        }
    }

    expandMacro(macro: string, node: SyntaxNode) {
        const node1 = this.resolve(macro, node);
        if (!node1) {
            return macro;
        }
        const save = this.code;
        this.generateCodeNode(node1);
        const code = this.code;
        this.code = save;
        return code;
    }

    expand(rule: CodeGenerationRule, node: SyntaxNode) {
        const code = rule.code;
        // TODO: Maybe pre-split in the map?
        const stringSegments = code.split('[');
        let ix = 0;
        for (const segment of stringSegments) {
            if (ix === 0) {
                this.append(node, rule, segment);
            } else {
                const [substitution, rest] = segment.split(']', 2) as [string, string];
                this.expandMacro(substitution, node);
                this.append(node, rule, rest);
            }
            ix++;
        }
    }

    generateCodeNode(node: SyntaxNode) {
        const prod = this.productionFor(node);
        const rule = this.map.get(prod);
        // rule *should* be defined, since the tree is parsed with the same rules
        if (rule && rule.code !== '') {
            this.expand(rule, node);
        } else {
            if (node.token) {
                // default for token: render value
                this.append(node, null, node.token.value);
            } else if (node.children.length > 1) {
                // Error for nodes with multiple children and no rule
                throw new ErrorResult(`Code generation rule not found for production: '${prod}'`);
            } else {
                // defualt for nodes with no rules and only one child: generate for child.
                this.generateCodeNode(node.children.first());
            }
        }
    }
}
