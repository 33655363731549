import { diagram } from './missions';
import { nandNodeType, inv16NodeType, selector16NodeType, and16NodeType, or16NodeType, xor16NodeType, splitterNodeType, bundlerNodeType, invNodeType } from './logicMissions';
import { zeroNodeType, add16NodeType, zero16NodeType, sub16NodeType } from './arithmeticMissions';
import { bit, word, Pin, PinGroup, pins } from '../pins';
import { numericTest } from '../verification';
import { wordToBitArray } from '../../common/bits';
import { component } from './baseNodeType';
import { OutputRuleArray } from './outputRules';
import { depends } from './dependency';
import { add, bitwiseAnd, bitwiseOr, bitwiseXor, inv, sub } from '../../common/arithmetics';
import { assembleInstruction } from '../../assembler/assembler';
import { conditionMission, resolveCondition } from './aluMissions';

// Same as conditionNodeType, but bit-pins are separate
export const condition2NodeType = component('condition',
    'CONDITION2',
    pins(bit('lt'), bit('eq'), bit('gt'), word('X')),
    pins(bit()),
    new OutputRuleArray(([lt, eq, gt, n]) => {
        return [resolveCondition(lt, eq, gt, n)];
    }),
    // cannot be expanded due to different number of pins in diagram
    depends(conditionMission, 1, false)
);

/* Revised ALU */

const samples2 = [
    [0, 0,],
    [0, 0xFFFF],
    [0xFFFF, 0],
    [0xFFFF, 0xFFFF],
    [0b1, 0xb1]
] as const;

function flagPermutations(cnt: 1): [number][];
function flagPermutations(cnt: 2): [number, number][];
function flagPermutations(cnt: 3): [number, number, number][];
function flagPermutations(cnt: 4): [number, number, number, number][];
function flagPermutations(cnt: 5): [number, number, number, number, number][];
function flagPermutations(cnt: number): number[][];
function flagPermutations(cnt: number): number[][] {
    return (cnt === 0) ? [[]] :
        flagPermutations(cnt - 1).flatMap(p => [0, 1].map(v => p.concat([v])));
}

const resolveLogicUnit = (op1: number, op0: number, x: number, y: number) =>
    op1 === 0 ?
        (op0 === 0 ? bitwiseAnd(x, y) : bitwiseOr(x, y)) :
        (op0 === 0 ? bitwiseXor(x, y) : inv(x));

const logicAluTests = flagPermutations(2).flatMap(([op1, op0]) => samples2.flatMap(([x, y]) =>
    numericTest(
        [op1, op0, x, y],
        [resolveLogicUnit(op1, op0, x, y)])));

export const logicAluMission = diagram({
    key: 'ALU_LOGIC',
    inputPins: [bit('op1'), bit('op0'),
        word('X'),
        word('Y'),
    ],
    outputPins: [word()],
    palette: [nandNodeType, selector16NodeType, inv16NodeType, and16NodeType, or16NodeType, xor16NodeType],
    tests: logicAluTests,
    score: { min: 7 },
} as const);

export const resolveArithmeticUnit = (op: number, y1: number, x: number, y: number) => {
    const xx = x;
    const yy = y1 === 1 ? 1 : y;
    return op === 1 ? sub(xx, yy) : add(xx, yy);
};
const arithmeticAluTests = flagPermutations(3).flatMap(([op1, op0]) => samples2.flatMap(([x, y]) =>
    numericTest(
        [op1, op0, x, y],
        [resolveArithmeticUnit(op1, op0, x, y)])));

export const arithmeticAluMission = diagram({
    key: 'ALU_ARITHMETIC',
    inputPins: [bit('op1'), bit('op0'),
        new Pin(16, 'X', 'signed'),
        new Pin(16, 'Y', 'signed')],
    outputPins: [word()],
    palette: [nandNodeType, selector16NodeType, add16NodeType, sub16NodeType, zeroNodeType, invNodeType, bundlerNodeType],
    tests: arithmeticAluTests,
    score: { min: 5 }
} as const);

export const logicUnitNodeType = component('logic unit',
    'LOGIC_UNIT',
    pins(bit('op1'), bit('op0'), word('X'), word('Y')),
    pins(bit()),
    new OutputRuleArray(([op1, op0, x, y]) => {
        const result = resolveLogicUnit(op1, op0, x, y);
        return [result];
    }),
    depends(logicAluMission)
);

export const arithmeticUnitNodeType = component('arithmetic unit',
    'ARITHMETIC_UNIT',
    pins(bit('op1'), bit('op0'), word('X'), word('Y')),
    pins(word()),
    new OutputRuleArray(([op, y1, x, y]) => {
        const res = resolveArithmeticUnit(op, y1, x, y);
        return [res];
    }),
    depends(arithmeticAluMission)
);

export const resolveAlu2 = (u: number, op1: number, op0: number, zx: number, sw: number, x: number, y: number) => {
    let [xx, yy] = sw === 1 ? [y, x] : [x, y];
    [xx, yy] = zx === 1 ? [0, yy] : [xx, yy];
    return u === 1 ?
        resolveArithmeticUnit(op1, op0, xx, yy) :
        resolveLogicUnit(op1, op0, xx, yy);
};

// Generates all flag-combinations for ALU
const alu2Tests = flagPermutations(5).flatMap(([u, op1, op0, zx, sw]) => samples2.flatMap(([x, y]) =>
    numericTest(
        [u, op1, op0, zx, sw, x, y],
        [resolveAlu2(u, op1, op0, zx, sw, x, y)])));

// A few more helpful ALU tests, directly testing the example flags
// [u, op1, op0, zx, sw, x, y],
const alu2FriendlyTests = [
    numericTest([1, 0, 0, 0, 0, 7, 4], [add(7, 4)]),
    numericTest([1, 1, 0, 0, 0, 7, 4], [sub(7, 4)]),
    numericTest([1, 1, 0, 0, 1, 7, 4], [sub(4, 7)]),
    numericTest([1, 1, 0, 1, 0, 7, 4], [sub(0, 4)]),
    numericTest([1, 1, 0, 1, 1, 7, 4], [sub(0, 7)]),
]

export const alu2Mission = diagram({
    key: 'ALU2',
    inputPins: [
        new PinGroup('',
            [bit('u'), bit('op1'), bit('op0'), bit('zx'), bit('sw')]),
        new Pin(16, 'X', 'signed'), new Pin(16, 'Y', 'signed')
    ],
    outputPins: [word('')],
    palette: [
        nandNodeType, logicUnitNodeType, arithmeticUnitNodeType, and16NodeType, add16NodeType, selector16NodeType, inv16NodeType, zero16NodeType,
    ],
    tests: [...alu2FriendlyTests, ...alu2Tests],
    score: { min: 6 }
} as const);

export const alu2NodeType = component('alu',
    'ALU2',
    pins(bit('u'), bit('op1'), bit('op0'), bit('zx'), bit('sw'), word('X'), word('Y')),
    pins(word()),
    new OutputRuleArray(([u, op1, op0, zx, sw, x, y]) => {
        const res = resolveAlu2(u, op1, op0, zx, sw, x, y);
        return [res];
    }),
    depends(alu2Mission)
);

const aluInstructionTests = [
        // in: instr, A, D, *A
        // out: X, target (a,d,*a), jmp
        numericTest([assembleInstruction('D = 1').toWord(), 7, 9, 13],
            [
                /* X */ 1, /*dest: */ 0, 1, 0, /* jmp */ 0,
            ]),
        numericTest([assembleInstruction('D,*A = D&A').toWord(), 0b110, 0b101, 0],
            [
                /* X */ 0b100, /*dest: */ 0, 1, 1, /* jmp */ 0,
            ]),
        numericTest([assembleInstruction('A = *A;JGE').toWord(), 0, 0, 42],
            [
                /* X */ 42, /*dest: */ 1, 0, 0, /* jmp */ 1,
            ]),
        numericTest([assembleInstruction('A = *A;JLE').toWord(), 0, 0, 42],
            [
                /* X */ 42, /*dest: */ 1, 0, 0, /* jmp */ 0,
            ]),
        numericTest([assembleInstruction('A = A - 1').toWord(), 42, 1, 2],
            [
                /* X */ 41, /*dest: */ 1, 0, 0, /* jmp */ 0,
            ]),
        numericTest([assembleInstruction('A = A - 1;JEQ').toWord(), 1, 2, 0xFFFF],
            [
                /* X */ 0, /*dest: */ 1, 0, 0, /* jmp */ 1,
            ])
];

export const aluInstructionMission = diagram({
    key: 'ALU_INSTRUCTION',
    inputPins: [
        word('I'),
        new PinGroup('State', [word('A'), word('D'), word('*A')]),
    ],
    outputPins: [word('R'), new PinGroup('Destination', [bit('a'), bit('d'), bit('*a')]), bit('j')],
    palette: [
        nandNodeType, alu2NodeType, condition2NodeType, splitterNodeType, and16NodeType, add16NodeType, selector16NodeType, inv16NodeType, zeroNodeType
    ],
    tests: aluInstructionTests,
} as const);

export const aluInstructionNodeType = component('alu instruction',
    'ALU_INSTRUCTION',
    pins(word('I'), word('A'), word('D'), word('*A')),
    pins(word('R'), bit('a'), bit('d'), bit('*a'), bit('j')),
    new OutputRuleArray(([i, a, d, m]) => {
        const [ , , , s, , u, op1, op0, zx, sw, da, dd, dm, lt, eq, gt] = wordToBitArray(i);
        const y = s === 1 ? m : a;
        const res = resolveAlu2(u, op1, op0, zx, sw, d, y);
        const j = resolveCondition(lt, eq, gt, res);
        return [res, da, dd, dm, j] as const;
    }),
    depends(aluInstructionMission)
);
