import { useState, useRef, useEffect, useContext } from 'react';
import './DiagramBuilder.css';
import { InputGroupComponent } from '../node/InputGroupComponent';
import { OutputGroupComponent } from '../node/OutputGroupComponent';
import { NodeComponent, NodeLocation } from '../node/NodeComponent';
import { Palette } from './Palette';
import { ComponentType, IComponentInstanceState } from '../../componentType';
import { InputConnector, OutputConnector } from '../../circuitStructure';
import { Pos, getPositionRelativeTo, ClientPos, getPointRelativeTo } from '../../position';
import { ComponentBuilder } from '../../componentBuilder';
import { WireComponent } from '../connection/WireComponent';
import React from 'react';
import { DragDropProvider, DragDropService } from '../drag.service';
import { InputConnectorLocation } from '../connector/InputConnectorComponent';
import { OutputConnectorLocation } from '../connector/OutputConnectorComponent';
import { Hint } from '../../hint';
import { ExpanderHelper } from '../../expanderHelper';
import { GameStateContext } from '../../../app/gameState';
import { DiagramObserver } from '../../../app/game/diagramHints';
import { EdgeInputConnectorState, EdgeOutputConnectorState, InputConnectorState, OutputConnectorState } from 'diagram/connectorState';
import { ComponentInstanceState } from 'diagram/componentState';

/* passed down to components and connectors */
export interface DiagramActions {
    clickConnectStart(connector: InputConnectorState): void;
    cancelConnectMode(): void;
    deleteOutputPin(pin: EdgeInputConnectorState): void;
    updateOutputPinLabel(pin: EdgeInputConnectorState, lbl: string): void;
    deleteInputPin(pin: EdgeOutputConnectorState): void;
    updateInputPinLabel(pin: EdgeOutputConnectorState, lbl: string): void;
    connect(output: OutputConnectorState): void;
    disconnect(connector: InputConnectorState): void;
    stateChanged: () => void;
    addNode: (nodeType: ComponentType, canvasPos: Pos) => void;
    deleteNode: (node: IComponentInstanceState) => void;
    expand: (node: ComponentInstanceState) => void;
    diagramStateChanged: ()=>void;
}


/* Handles the DOM locations of nodes and connectors, which make wires and connections possible  */
export class CanvasService {
    constructor(readonly connectionsChanged: ()=>void) { }

    canvasElement!: HTMLElement;

    readonly outputConnectors = new WeakMap<OutputConnector, OutputConnectorLocation>();
    readonly inputConnectors = new Map<InputConnector, InputConnectorLocation>();

    /* Keep track of input/output connector components, so we can draw wires */
    registerInputConnectorComponent(comp: InputConnectorLocation) {
        this.inputConnectors.set(comp.connector.connector, comp);
        this.connectionsChanged();
    }
    unregisterInputConnectorComponent(comp: InputConnectorLocation) {
        this.inputConnectors.delete(comp.connector.connector);
        this.connectionsChanged();
    }
    registerOutputConnectorComponent(comp: OutputConnectorLocation) {
        if (comp.connector instanceof OutputConnectorState) {
            this.outputConnectors.set(comp.connector.connector, comp);
        }
        this.connectionsChanged();
    }
    unregisterOutputConnectorComponent(comp: OutputConnectorLocation) {
        if (comp.connector instanceof OutputConnectorState) {
            this.outputConnectors.delete(comp.connector.connector);
        }
        this.connectionsChanged();
    }
    getOutputConnectorComponent(conn: OutputConnector) {
        return this.outputConnectors.get(conn);
    }

    // get the position of an element relative to the canvas of an absolutely positioned element
    // (used when dropping elements from the palette)
    getRelativePos(elem: HTMLElement): Pos {
        const canvasElem = this.canvasElement;
        return getPositionRelativeTo(elem, canvasElem);

    }
    getRelativePos2(clientPos: ClientPos): Pos {
        const canvasElem = this.canvasElement;
        return getPointRelativeTo(clientPos, canvasElem);
    }
}

export const CanvasContext = React.createContext(null as unknown as CanvasService);


export function DiagramBuilder({ model, listener }: { model: ComponentBuilder, listener: DiagramObserver}) {
    const gameStateService = useContext(GameStateContext);

	const [state, setState] = useState(()=>{
        const canvasService = new CanvasService(connectionsChanged);
		return {
           nodes: model.diagram.nodes,
           canvasService: canvasService,
           connectMode: false,
           selectedConnector: undefined as InputConnectorState | undefined,
           hints: undefined as Hint[] | undefined,
           dragDropService: new DragDropService(),
		};
    });

    // called when connection registered or de-registered
    function connectionsChanged() {
        setState({...state});
    }
    // called when dropped from palette
    function addNode(type: ComponentType, pos: Pos) {
        const node = model.diagram.addNode(type, pos);
        listener.nodeAdded(node);
        setState({...state, nodes: model.diagram.nodes});
    }

    function deleteNode(node: IComponentInstanceState) {
        node.delete();
        setState({...state, nodes: model.diagram.nodes});
    }
    function deleteConnection(connector: InputConnectorState) {
        connector.deleteConnection();
        connectionsChanged();
        listener.connectionDeleted(connector);
    }
    function diagramStateChanged() {
        setState({...state, nodes: model.diagram.nodes});
    }

    function canvasResized() {
        setState({...state});
    }

    const canvasService = state.canvasService;

    const connectionComponents =  Array.from(canvasService.inputConnectors.values())
            .filter(c => c.connector.connection !== undefined)
            .map(c => {
                const connection = c.connector.connection!;
                const source = canvasService.getOutputConnectorComponent(connection.sourceConnector)!;
                return {to:c, from:source}})
            // connectors might not be rendered yet, so we just skip rendering the wire
            .filter(({from})=> from !== undefined);

    const diagram =  model.diagram;

    const resizableToolAreaRef = useRef<HTMLDivElement>(null);
    const canvasRef = useRef<HTMLDivElement>(null);
    const componentCanvasRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // Add observer so we can redraw wires when window is resized
        const resizableToolArea = resizableToolAreaRef.current!;
        const ro = new ResizeObserver(_entries => { canvasResized(); });
        ro.observe(resizableToolArea);
        canvasService.canvasElement = canvasRef.current!;

        return () => {
            // destruct
            // state.dragDropService.unbind();
            console.log('end effect');
        }
      }, []);

    function addInputPin(width: number) {
        model.addInputPin('', width);
        setState({...state});
    }
    function addOutputPin(width: number) {
        model.addOutputPin('', width);
        setState({...state});
    }
    function enterConnectMode(connector: InputConnectorState) {
        listener.connectModeActivated(connector);
        setState({...state, connectMode: true, selectedConnector: connector})
    }
    function cancelConnectMode() {
        listener.connectModeCancelled();
        setState({...state, connectMode: false, selectedConnector: undefined})
    }
    function connect(output: OutputConnectorState) {
        if (state.selectedConnector) {
            const input = state.selectedConnector;
            input.deleteConnection();
            input.createConnection(output);
            listener.connectionCreated(input, output);
            setState({...state, connectMode: false, selectedConnector: undefined})
        }
    }
    function moveNode() {
       setState({...state});
    }
    function expand(node: ComponentInstanceState) {
        const expanderHelper = new ExpanderHelper(gameStateService);
        expanderHelper.expand(node);
        // trigger re-render
        diagramStateChanged();
        window.setTimeout(()=>{
            // rescale after new nodes have been rendered
            rescale();
        }, 0);
    }
    /* rescale diagram if nodes are outside the bounds of the canvas */
    function rescale() {
        // the component canvas is the blue area
        const componentCanvas = componentCanvasRef.current!;
        // get node areas
        const nodes = canvasRef.current!.querySelectorAll<HTMLDivElement>('.free-node');
        const areas = Array.from(nodes).map(n => new NodeLocation(n, canvasService).area);
        if (areas.length === 0) {
            return;
        }
        const right = areas.map(n => n.right).max();
        const bottom = areas.map(n => n.bottom).max();
        const canvasSize = new Pos(componentCanvas.offsetWidth, componentCanvas.offsetHeight);
        const scaleX = right > canvasSize.x ? canvasSize.x / right : 1;
        const scaleY = bottom > canvasSize.y ? canvasSize.y / bottom : 1;
        if (scaleX === 1 && scaleY === 1) {
            return;
        }
        for (const node of model.diagram.nodes) {
            node.moveTo(new Pos(node.pos.x * scaleX, node.pos.y * scaleY));
        }
        // force render
        setState({...state});
        // force render again to render wires
        window.setTimeout(()=>{ connectionsChanged();}, 0);
    }

    // metods passed down
    const diagramActions: DiagramActions = {
        deleteOutputPin: (connector: EdgeInputConnectorState) => {
            model.deleteOutputPin(connector);
            setState({...state});
        },
        updateOutputPinLabel: (connector: EdgeInputConnectorState, newLbl: string) => {
            connector.connector.setLabel(newLbl);
            setState({...state});
        },
        deleteInputPin: (connector: EdgeOutputConnectorState) => {
            model.deleteInputPin(connector);
            setState({...state});
        },
        updateInputPinLabel: (connector: EdgeOutputConnectorState, newLbl: string) => {
            connector.connector.setLabel(newLbl);
            setState({...state});
        },
        connect: (output: OutputConnectorState) => {
            // connect the output to the currently selected input connector
            connect(output);
        },
        clickConnectStart: (connector: InputConnectorState) => {
            enterConnectMode(connector);
        },
        cancelConnectMode: () => { cancelConnectMode(); },
        disconnect: (connector: InputConnectorState) => { deleteConnection(connector); },
        stateChanged: () => { diagramStateChanged(); },
        addNode: (nodeType: ComponentType, canvasPos: Pos) => { addNode(nodeType, canvasPos); },
        deleteNode: (node: IComponentInstanceState) =>{ deleteNode(node); },
        expand: (node: ComponentInstanceState) => { expand(node); },
        diagramStateChanged: () => { diagramStateChanged(); }
    };

    function canvasClick() {
        if (state.connectMode) {
            diagramActions.cancelConnectMode();
        }
    }

	return (<div id='tool' ref={resizableToolAreaRef} onContextMenu={()=>false} className='tool'>
        <CanvasContext.Provider value={canvasService}>
        <DragDropProvider.Provider value={state.dragDropService} >
        <Palette model={model} addNode={addNode} />

        <div className='canvas-panel'>
            <div ref={canvasRef} id='canvas' className='canvas' onClick={canvasClick}>

                <svg className='wire-layer'>
                    {(connectionComponents).map(({to, from}) =>
                        <g key={to.connector.id}><WireComponent inputConnectorComponent={to} outputConnectorComponent={from} /></g>)}
                </svg>

                <div className='nodes'>
                    {(state.nodes).map(node =>
                        <div key={node.id}>
                        <NodeComponent node={node} isPalette={false}
                            selectedConnector={state.selectedConnector}
                            move={moveNode}
                            diagramActions={diagramActions}
                            />
                    </div>)}
                </div>

                <div className='output-node-row'>
                    <div className='output-row-label diagram-label'>Output:</div>
                    {(diagram.outputGroups).map((group, ix) => (
                        <OutputGroupComponent key={ix} group={group} model={model}
                            selectedConnector={state.selectedConnector}
                            diagramActions={diagramActions} />
                    ))}

                    { model.canModifyPins && (
                        <div className='dropdown-area'>
                        <div className='dropdown'>
                            <button type='button' data-bs-toggle='dropdown' aria-haspopup='true' aria-expanded='false' className='btn btn-secondary dropdown-toggle'>
                                <i className='bi bi-node-plus'></i> Add Output <span className='caret'></span>
                            </button>
                            <ul className='dropdown-menu'>
                                <li><button onClick={()=>addOutputPin(1)} className='dropdown-item'>Add 1 bit output</button></li>
                                <li><button onClick={()=>addOutputPin(16)} className='dropdown-item'>Add 16 bit output</button></li>
                            </ul>
                        </div>
                    </div>)}
                </div>

                <div ref={componentCanvasRef} className='component-canvas node-droptarget'></div>

                <div className='input-node-row'>
                    <div className='input-row-label diagram-label'>Input:</div>
                    {(diagram.inputGroups).map((group, ix) => (
                        <InputGroupComponent key={ix} group={group} model={model} isPalette={false} isFree={false}
                           selectedConnector={state.selectedConnector}
                           diagramActions={diagramActions}
                           />
                    ))}

                    { model.canModifyPins && (
                        <div className='dropdown-area'>
                        <div className='btn-group custom-input-dropup'>
                            <button type='button' data-bs-toggle='dropdown' aria-haspopup='true' aria-expanded='false' className='btn btn-secondary dropdown-toggle'>
                                <i className='bi bi-node-plus'></i> Add Input <span className='caret'></span>
                            </button>
                            <ul className='dropdown-menu'>
                                <li><button onClick={()=>addInputPin(1)} className='dropdown-item'>Add 1 bit input</button></li>
                                <li><button onClick={()=>addInputPin(16)} className='dropdown-item'>Add 16 bit input</button></li>
                            </ul>
                        </div>
                    </div>)}
                </div>
            </div>
        </div>

        <div id='tool-overlays'></div>
        </DragDropProvider.Provider>
        </CanvasContext.Provider>
    </div>
);
}



