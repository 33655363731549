import './lamp.css';
import './led.css';
import './relays.css';
import './node.css';

import { IComponentInstanceState } from '../../componentType';
import { LampInternalState } from 'diagram/missions/lamp';



export function DiagramNodeWireJunction(props: {
		node: IComponentInstanceState}) {

    const inputs =  props.node.inputConnectorStates;
    const outputs =  props.node.outputConnectorStates;

	return (
            <svg width='80' height='40' className='wire-junction'>
                <circle cx='40' cy='21' r='4' className={'wire' + (outputs.itemAt(0).state ? ' on' : '')}></circle>
                <line x1='40' y1='0' x2='40' y2='20' className={'wire' + (outputs.itemAt(0).state ? ' on' : '')}></line>
                <line x1='40' y1='20' x2='20' y2='40' className={'wire downleg' + (inputs.itemAt(0).state ? ' on' : '')}></line>
                <line x1='40' y1='20' x2='60' y2='40' className={'wire downleg' + (inputs.itemAt(1).state ? ' on' : '')}></line>
            </svg>);
}

export function LampViewComponent({ isOn } : { isOn: boolean }) {
    return (
        <div className={'lamp-node-box' + (isOn ? ' on' : '')}>
            <div className='lamp-outer'>
                <div className='lamp'></div>
            </div>
        </div>);
}

export function LampComponent(props: {node: IComponentInstanceState}) {
    const internalState =  props.node.internalState as LampInternalState;
    const isOn =  internalState.isOn;
	return <LampViewComponent isOn={isOn} />;
}


export function LedComponent(props: {node: IComponentInstanceState}) {
    const state =  props.node.inputConnectorStates.first().numState === 1;
    return (
        <div className='led-node-box'>
            <div className={'led-node-' + (state ? 'on' : 'off')}></div>
        </div>);
}


