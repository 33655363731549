import { resolveCondition } from "./aluMissions";
import { component } from "./baseNodeType";
import { nandNodeType, selector16NodeType, splitterNodeType, bundlerNodeType, invNodeType, orNodeType, selectorNodeType } from './logicMissions';
import { isNegNodeType, zeroNodeType } from './arithmeticMissions';
import { numericTest } from "../verification";
import { assembleInstruction } from "../../assembler/assembler";
import { bit, PinGroup, pins, word } from "../pins";
import { aluInstructionNodeType, resolveAlu2 } from "./alu2missions";
import { wordToBitArray } from "../../common/bits";
import { diagram } from "./missions";
import { OutputRuleArray } from "./outputRules";
import { depends } from "./dependency";
import { controlSelectorNodeType } from "./controlSelectorMission";


const controlUnitMissionTests = [
    // in: instr, A, D, *A
    // out: X, target (a,d,*a), jmp
    numericTest([assembleInstruction('A = 1234').toWord(), 7, 9, 13],
    [
        /* X */ 1234, /*dest: */ 1, 0, 0, /* jmp */ 0,
    ]),
    numericTest([assembleInstruction('D = 1').toWord(), 7, 9, 13],
        [
            /* X */ 1, /*dest: */ 0, 1, 0, /* jmp */ 0,
        ]),
    numericTest([assembleInstruction('D,*A = D&A').toWord(), 0b110, 0b101, 0],
        [
            /* X */ 0b100, /*dest: */ 0, 1, 1, /* jmp */ 0,
        ]),
    numericTest([assembleInstruction('A = *A;JGE').toWord(), 0, 0, 42],
        [
            /* X */ 42, /*dest: */ 1, 0, 0, /* jmp */ 1,
        ]),
    numericTest([assembleInstruction('A = A - 1').toWord(), 42, 1, 2],
        [
            /* X */ 41, /*dest: */ 1, 0, 0, /* jmp */ 0,
        ])
];

export const controlUnitMission = diagram({
    key: 'CONTROL_UNIT',
    inputPins: [
        word('I'),
        new PinGroup('State', [word('A'), word('D'), word('*A')]),
    ],
    outputPins: [word('R'), new PinGroup('Destination', [bit('a'), bit('d'), bit('*a')]), bit('j')],
    palette: [
        nandNodeType, orNodeType, 
        controlSelectorNodeType,
        aluInstructionNodeType, splitterNodeType, bundlerNodeType,
        selectorNodeType,
        selector16NodeType,
        isNegNodeType,
        invNodeType,
        zeroNodeType,
    ],
    tests: controlUnitMissionTests,
} as const);

export const resolveControlUnit = (i: number, a: number, d:number, m: number) => {
    const [ci, , , s, , u, op1, op0, zx, sw, da, dd, dm, lt, eq, gt] = wordToBitArray(i);
    if (ci===1) {
        const y = s === 1 ? m : a;
        const res = resolveAlu2(u, op1, op0, zx, sw, d, y);
        const j = resolveCondition(lt, eq, gt, res);
        return [res, da, dd, dm, j] as const;
    } else {
        return [i, 1, 0, 0, 0] as const;
    }
};

export const controlUnitNodeType = component('control unit',
    'CONTROL_UNIT',
    pins(word('I'), word('A'), word('D'), word('*A')),
    pins(word('R'), bit('a'), bit('d'), bit('*a'), bit('j')),
    new OutputRuleArray(([i, a, d, m]) => resolveControlUnit(i, a, d, m)),
    depends(controlUnitMission)
);
