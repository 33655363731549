import { component } from "./baseNodeType";
import { nandNodeType, selectorNodeType, selector16NodeType, splitterNodeType } from './logicMissions';
import { numericTest } from "../verification";
import { bit, PinGroup, pins, word } from "../pins";
import { diagram } from "./missions";
import { OutputRuleArray } from "./outputRules";
import { depends } from "./dependency";
import { isNegNodeType } from "./arithmeticMissions";


const controlSelectorMissionTests = [
    numericTest([0, 42, 0, 1, 0, 1, 0xff00, 1, 0, 1, 0], [0xff00, 1, 0, 1, 0]),
    numericTest([1, 42, 0, 1, 0, 1, 0xff00, 1, 0, 1, 0], [42, 0, 1, 0, 1]),
];

export const controlSelectorMission = diagram({
    key: 'CONTROL_SELECTOR',
    inputPins: [
        bit('s'),
        new PinGroup('Control bus 1', [word('R₁'), bit('a₁'), bit('d₁'), bit('*a₁'), bit('j₁')]),
        new PinGroup('Control bus 0', [word('R₀'), bit('a₀'), bit('d₀'), bit('*a₀'), bit('j₀')]),
    ],
    outputPins: [word('R'), new PinGroup('', [bit('a'), bit('d'), bit('*a')]), bit('j')],
    palette: [
        nandNodeType,
        selectorNodeType,
        selector16NodeType,
        splitterNodeType,
        isNegNodeType,
    ],
    tests: controlSelectorMissionTests,
} as const);

export const resolvecontrolSelector = (
    s:number, 
    R1:number, a1:number, d1:number, m1:number, j1:number,
    R2:number, a2:number, d2:number, m2:number, j2:number,
) => {
    if (s===1) {
        return [R1, a1, d1, m1, j1] as const;
    } else {
        return [R2, a2, d2, m2, j2] as const;
    }
};

export const controlSelectorNodeType = component('control selector',
    'CONTROL_SELECTOR',
    [
        new PinGroup('', [bit('s')]),
        new PinGroup('', [word('R₁'), bit('a₁'), bit('d₁'), bit('*a₁'), bit('j₁')]),
        new PinGroup('', [word('R₀'), bit('a₀'), bit('d₀'), bit('*a₀'), bit('j₀')]),
    ],
    pins(word('R'), bit('a'), bit('d'), bit('*a'), bit('j')),
    new OutputRuleArray(([s, R1,a1,d1,m1,j1, R0,a0,d0,m0,j0]) => resolvecontrolSelector(s, R1, a1,d1,m1,j1,R0,a0,d0,m0,j0)),
    depends(controlSelectorMission),
);