import { Boolean16, DenseArray } from 'common/utilities';
import { bitToBool, boolToBit } from '../../common/bits';
import { ComponentInstanceState } from 'diagram/componentState';


/* Specifies how a components resolves output based on input */
export interface OutputRule {
    resolve(node: ComponentInstanceState): readonly number[];
    resolve1(inp: number[]): readonly number[];
}

export class OutputRuleArray implements OutputRule {
    constructor(readonly rule: (a: DenseArray) => readonly number[]) { }
    resolve(node: ComponentInstanceState) {
        const inputs = node.inputConnectorStates.map(i => i.numState) as readonly number[];
        return this.rule(inputs as DenseArray);
    }
    resolve1(inp: readonly number[]) {
        return this.rule(inp as DenseArray);
    }
}

export class OutputRuleBooleanArray implements OutputRule {
    constructor(readonly rule: (a: Boolean16) => readonly boolean[]) { }
    resolve(node: ComponentInstanceState) {
        const inputs = node.inputConnectorStates.map(i => i.bitState) as readonly boolean[] as Boolean16;
        return this.rule(inputs).map(n => boolToBit(n));
    }
    resolve1(inp: number[]) {
        const results = this.rule(inp.map(i => bitToBool(i)) as readonly boolean[] as Boolean16);
        return results.map(n => boolToBit(n));
    }
}

// A rule for an output connector based on two input connectors
export class OutputRuleBinary implements OutputRule {
    constructor(readonly rule: (a: boolean, b: boolean) => boolean) { }
    resolve(node: ComponentInstanceState) {
        const a = node.inputConnectorStates.itemAt(0).bitState;
        const b = node.inputConnectorStates.itemAt(1).bitState;
        const result = this.rule(a, b);
        return [result ? 1 : 0];
    }
    resolve1(inp: [number, number]) {
        const result = this.rule(bitToBool(inp[0]), bitToBool(inp[1]));
        return [boolToBit(result)];
    }
}
