import './edge-group.css';
import { InputNodeComponent } from './InputNodeComponent';
import { InputStateGroup } from '../../circuitState';
import { ComponentBuilder } from '../../componentBuilder';
import { DiagramActions } from '../builder/DiagramBuilder';
import { InputConnectorState } from 'diagram/connectorState';

export function InputGroupComponent(props: {
        isPalette: boolean;
        isFree: boolean;
		group: InputStateGroup;
		model: ComponentBuilder;
        selectedConnector: InputConnectorState | undefined;
        diagramActions: DiagramActions;
    }) {

    const inputNodes =  props.group.nodes;
    const multi =  props.group.nodes.length > 1;
    const hasLabel =  props.group.label !== undefined;
    const label =  props.group.label;
	const model = props.model;

    const pins = (inputNodes).map((connector, ix) => (
        <InputNodeComponent key={ix} connector={connector} model={model} isPalette={props.isPalette} isFree={props.isFree}
        selectedConnector={props.selectedConnector}
        diagramActions={props.diagramActions}
         />));

    if (multi) {
        return  (<div className='diagram-input-group multi'>
            <div className='component-row'>
                {pins}
            </div>
            { hasLabel && <div className='diagram-group-label'>{label}</div> }
        </div>);
    } else {
        return  (<div className='diagram-input-group'>
            <div className='component-row'>
                {pins}
            </div>
        </div>);
    }
}

