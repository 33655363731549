import { Range, editor, languages } from 'monaco-editor';
import { Monaco } from '@monaco-editor/react';
import { IEditorBackend } from './iEditorBackend';


export class EditorConfigurator {
    decorations: string[] = [];
    hovers: languages.Hover[] = [];
    backend!: IEditorBackend;

    constructor(readonly monaco: Monaco, readonly codeEditorInstance: editor.IStandaloneCodeEditor, backend: IEditorBackend) {
        this.setBackend(backend);

        codeEditorInstance.updateOptions({
            lineNumbers: ln => this.backend.mapLineNo(ln),
        });

        codeEditorInstance.getModel()?.onDidChangeContent(e => {
            this.onDidChangeContent(e);
        });
        this.updateRender();


    }
    onDidChangeContent(_e: editor.IModelContentChangedEvent) {
        this.backend.updateCode(this.codeEditorInstance.getValue());
        this.updateRender();
    }
    updateCurrentLine() {
        // update current line
        if (this.backend.currentLineIx !== undefined) {
            const currentLineNo = this.backend.currentLineIx + 1;

            // update current line
            this.decorations = this.codeEditorInstance.deltaDecorations(this.decorations, [
                {
                    range: new Range(currentLineNo, 1, currentLineNo, 1),
                    options: {
                        isWholeLine: true,
                        className: 'executing-line',
                        glyphMarginClassName: 'executing-line-glyph',
                        inlineClassName: 'executing-line'
                    },
                },
            ]);
            // reveal current line
            this.codeEditorInstance.revealLineInCenterIfOutsideViewport(currentLineNo);
        } else {
            // Remove current line hightlight
            this.decorations = this.codeEditorInstance.deltaDecorations(this.decorations, []);
        }
    }
    // When a different mission is selected, we change the backend,
    setBackend(backend: IEditorBackend) {
        this.backend = backend;
        // TODO: How to avoid adding multiple times?
        backend.currentLineChanged = () => this.updateCurrentLine();
        this.codeEditorInstance.setValue(this.backend.getText());
    }

    updateRender() {
        this.updateCurrentLine();

        // update error squiggles
        // mark all errors
        const modelMarkers = [];
        const invalidLines = this.backend.getLines().filter(l => !l.isValid);
        for (const line of invalidLines) {
            const invalidTokens = line.tokens.filter(t => !t.isValid);
            for (const token of invalidTokens) {
                modelMarkers.push({
                    severity: 5,
                    message: line.errorText ?? 'Error',
                    startLineNumber: token.location.lineNumber,
                    startColumn: token.location.startColumn,
                    endLineNumber: token.location.lineNumber,
                    endColumn: token.location.endColumn,
                });
            }
            if (invalidTokens.length === 0) {
                // mark whole line as error if no specific token marked
                const firstToken = line.tokens.first();
                const lastToken = line.tokens.at(-1)!;
                modelMarkers.push({
                    severity: 5,
                    message: line.errorText ?? 'Error',
                    startLineNumber: firstToken.location.lineNumber,
                    startColumn: firstToken.location.startColumn,
                    endLineNumber: lastToken.location.lineNumber,
                    endColumn: lastToken.location.endColumn,
                });
            }
        }

        this.monaco.editor.setModelMarkers(this.codeEditorInstance.getModel()!, 'x', modelMarkers);

        /* Update hovers */
        /*
        const tokens = this.backend.program.lines.filter(l => l.isValid).flatMap(l => l.tokens);
        for (const token of tokens) {
            this.hovers.push({
                range: new monaco.Range(
                    token.location.lineNumber, token.location.startColumn,
                    token.location.lineNumber, token.location.endColumn),
                contents: [
                    { value: `**${token.type}**` },
                ]
            });
        }
        */
    }
}

export class LanguageConfiguration {
    static init(monaco: Monaco) {
        // TODO: This is called multiple times. Should be a singleton/context
        console.log('LANG CONFIG init')
        monaco.editor.defineTheme('NandgameAssemblerTheme', {
            base: 'vs-dark',
            inherit: true,
            rules: [
                { token: 'comment', foreground: '6A9955', fontStyle: 'italic' },
                { token: 'operator', foreground: 'ffffff' },
                {
                    token: 'identifier',
                    foreground: '9CDCFE',
                    fontStyle: 'bold',
                },
                { token: 'number', foreground: 'ffffff' },
                { token: 'register', foreground: '4EC9B0' },
                { token: 'jump', foreground: 'DCDCAA', fontStyle: 'bold' },
            ],
            colors: {},
        });
        monaco.languages.register({ id: 'NandgameAssembler' });
        monaco.languages.setLanguageConfiguration('NandgameAssembler', {
            comments: { lineComment: '#' },
        });
       this.registerLanguageEvents(monaco);
    }
    static getModifier(modifiers: string[] | string | null): number {
        if (typeof modifiers === 'string') {
            modifiers = [modifiers];
        }
        if (Array.isArray(modifiers)) {
            let nModifiers = 0;
            for (const modifier of modifiers) {
                const nModifier = this.legend.tokenModifiers.indexOf(modifier);
                if (nModifier > -1) {
                    nModifiers |= (1 << nModifier) >>> 0;
                }
            }
            return nModifiers;
        } else {
            return 0;
        }
    }  static legend = {
        tokenTypes: ['comment', 'number', 'register', 'operator', 'punctuation', 'jump', 'placeholder', 'identifier', 'error'],
        tokenModifiers: [
            // TODO - this is just some copy-pasted garbage
            'declaration',
            'documentation',
            'readonly',
            'static',
            'abstract',
            'deprecated',
            'modification',
            'async',
        ],
    };

    static pathToBackend = new Map<string, IEditorBackend>();
    static registerBackend(editorBackend: IEditorBackend){
        // paths are normalized to start with slash
        let path = editorBackend.path;
        if (!path.startsWith('/')) {
            path = '/' + path;
        }
        this.pathToBackend.set(path, editorBackend);
    }

    static getType(type: string): number {
        return this.legend.tokenTypes.indexOf(type);
    }
    static registerLanguageEvents(monaco: Monaco) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const outer = this;
        monaco.languages.registerDocumentSemanticTokensProvider('NandgameAssembler', {
            getLegend: function () {
                return outer.legend;
            },
            provideDocumentSemanticTokens: (model, _lastResultId, _token) => {
                const backend = outer.pathToBackend.get(model.uri.path);
                if (!backend) {
                    return {
                        data: new Uint32Array([]),
                        resultId: undefined,
                    };
                }

                /* API is a bit weird - we provide a list of numbers,
                 * where each set of 5 numbers represent a token.
                 *   lineDelta, columnDelta, length, type, modifiers
                 * line and column are relative offsets from the end of the previous token
                 * type is an index into the list of token types, modifiers is a bit-flag */
                const data = [];
                let prevLine = 0;
                let prevChar = 0;
                for (const line of backend.getLines()) {
                    for (const token of line.tokens) {
                        const type = outer.getType(token.type);
                        if (type !== -1) {
                            const modifier = outer.getModifier(null);
                            const lineOffset = line.lineOffset;
                            const col = token.location.charOffset;
                            data.push(
                                // translate line to deltaLine
                                lineOffset - prevLine,
                                // for the same line, translate start to deltaStart
                                prevLine === lineOffset ? col - prevChar : col,
                                token.location.length,
                                type,
                                modifier
                            );
                            prevLine = lineOffset;
                            prevChar = col;
                        }
                    }
                }
                return {
                    data: new Uint32Array(data),
                    resultId: undefined,
                };
            },
            releaseDocumentSemanticTokens: function (_resultId) {
                /* nothing to release since we dont cache the tokens */
            },
        });
        /* TODO TODO
        monaco.languages.registerHoverProvider('NandgameAssembler', {
            provideHover: (_model, position) => {
                // called for every mouse move, so we cache the hovers on edits.
                // here we find the hovers which cover the position
                return this.hovers.find(
                    h => h.range &&
                        position.lineNumber === h.range.startLineNumber &&
                        position.column >= h.range.startColumn &&
                        position.column < h.range.endColumn
                );
            },
        });
        */
        monaco.languages.registerCompletionItemProvider('NandgameAssembler', {
            provideCompletionItems: (_model, _position) => {
                /*
                // find out if we are completing a property in the 'dependencies' object.
                var textUntilPosition = model.getValueInRange({startLineNumber: 1, startColumn: 1, endLineNumber: position.lineNumber, endColumn: position.column});
                var match = textUntilPosition.match(/"dependencies"\s*:\s*\{\s*("[^"]*"\s*:\s*"[^"]*"\s*,\s*)*([^"]*)?$/);
                if (!match) {
                    return { suggestions: [] };
                }
                var word = model.getWordUntilPosition(position);
                var range = {
                    startLineNumber: position.lineNumber,
                    endLineNumber: position.lineNumber,
                    startColumn: word.startColumn,
                    endColumn: word.endColumn
                };
                return {
                    suggestions: createDependencyProposals(range)
                };
                */
                // for now we just return an empty set, to avoid annoying suggestions
                console.log('suggestions...');
                return {
                    suggestions: [],
                };
            },
        });
    }
}
