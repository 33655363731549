import React from 'react';
import { MemoryMappedDevice } from '../../assembler/machine';
import { numberToBoolArray } from '../../common/bits';
import './DisplayComponent.css';

export class DisplayDevice implements MemoryMappedDevice {
    inputState = 0;
    rows = 256;
    colWords = 32;
    data = Array(this.rows * this.colWords).fill(0) as number[];
    poke(addr: number, word: number) {
        // console.log('poke display', addr.toString(16), word.toString(2));
        this.pokeImmediately(addr, word);
    }
    pokeImmediately(addr: number, word: number) {
        this.data[addr] = word;
        return this.peek(addr);
    }
    peek(addr: number) { return this.data[addr] ?? 0; }
    tick() { }
    reset() {
        this.data.fill(0);
    }
}

export function getFigureBounds(display: DisplayDevice) {
    let top = undefined;
    let bottom = undefined;
    let left = undefined;
    let right = undefined;
    for (let y = 0; y < display.rows; y++) {
        const lineAsBooleans = display.data.slice(y * display.colWords, (y + 1) * display.colWords)
            .flatMap(word => numberToBoolArray(word, 16));
        const leftmostDot = lineAsBooleans.indexOf(true);
        const rightmostDot = lineAsBooleans.lastIndexOf(true);
        if (leftmostDot > -1) {
            if (top === undefined) {
                top = y;
            }
            bottom = y;
            if (left === undefined || left > leftmostDot) {
                left = leftmostDot;
            }
            if (right === undefined || right < rightmostDot) {
                right = rightmostDot;
            }
        }
    }
    if (top === undefined || bottom === undefined || left === undefined || right === undefined) {
        return undefined;
    }
    const height = bottom - top + 1;
    const width = right - left + 1;
    return { width, height };
}

export function DisplayComponent(props: { displayIo: DisplayDevice }) {
    const width = 512;
    const height = 256;
    const canvasRef = React.useRef<HTMLCanvasElement>(null);
    function render(canvas: HTMLCanvasElement) {
        const context = canvas.getContext('2d')!;
        const imageData = context.getImageData(0, 0, width, height);
        const data = imageData.data;
        const words = props.displayIo.data;
        for (let i = 0; i < words.length; i++) {
            const word = words.itemAt(i);
            const bits = numberToBoolArray(word, 16);
            for (let j = 0; j < 16; j ++) {
                const pixelIx = i * 16 + j;
                const bit = bits[j];
                // four bytes per pixel: r,g,b,a
                // + 1 to get green channel
                data[pixelIx * 4 + 1] = bit ? 0xdd : 0x33;
            }
        }
        context.putImageData(imageData, 0, 0);

    }
    if (canvasRef.current) {
        render(canvasRef.current);
    }
    React.useEffect(() => {
        const canvas = canvasRef.current!;
        const context = canvas.getContext('2d')!;
        // Clear screen
        context.fillStyle = '#003300'
        context.fillRect(0, 0, width, height);
        render(canvas);
    }, [])
    return (
        <div className="display-box">
            <div className="screen-outer">
                <div className="screen">
                    <canvas ref={canvasRef} width={width} height={height}></canvas>
                </div>
            </div>
        </div>
    );
}
