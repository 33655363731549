import { MemoryState, DelegateStateView } from '../stateViews';
import { ComponentCounter, ComponentInternalState, StateView } from '../componentType';
import { BaseBuiltinComponentType } from './baseNodeType';
import { pins, word } from '../pins';
import { ComponentInstance } from '../circuitStructure';
import { atomic } from './dependency';
import { ComponentInstanceState } from 'diagram/componentState';


/*
 * ROM program is stored in persistent state per compinent instance
 */
export class RomProgram {
    constructor(public words: number[]) { }
    save() { }
    peek(address: number) {
        // if the value at the address have not been specified, it is undefined. We default to 0
        if (address >= this.words.length) {
            return 0;
        }
        return this.words.itemAt(address);
    }
    peekState = (addr: number) => this.peek(addr);
    resolve(addr: number) {
        const value = this.peek(addr);
        return [value] as const;
    }
    reset() { }
}

export class RomState implements ComponentInternalState, MemoryState {
    readonly stateView;
    constructor(readonly node: ComponentInstanceState, readonly program: RomProgram) {
        this.stateView = new RomStateView(this, this.program);
    }
    resolveOutputs(node: ComponentInstanceState) {
        const romState = node.componentInstance.persistentState as RomProgram;
        const addr = node.inputConnectorStates.itemAt(0).numState;
        return romState.resolve(addr);
    }
    peekState(addr: number) {
        return new DelegateStateView(() => this.program.peek(addr), 'InstructionCompact');
    }
    get address() { return this.node.inputConnectorStates.itemAt(0).numState; }
    resolve = (addr: number) => this.program.resolve(addr);
    reset() { }
}

type RomPersistence = { words: number[] };

export const romNodeType = new class extends BaseBuiltinComponentType {
    readonly name = 'rom';
    readonly key = 'ROM';
    readonly inputs = pins(word('Ad'));
    readonly outputs = pins(word());
    readonly displayHint = 'rom';
    readonly hasPersistentState = true;
    readonly depends = atomic();
    createInternalState = (node: ComponentInstanceState) =>
        new RomState(node, node.componentInstance.persistentState as RomProgram);
    // This overrides the dependency specification:
    componentCount = (counter: ComponentCounter) =>
        counter.empty(`(ROM storage not counted)`);
    /* The persistent state is the ROM program stored as an array of numbers */
    initPersistentState =
        () => new RomProgram([1, 2, 3, 4, 5, 6, 7, 8]);
    getPersistentState = (node: ComponentInstance) =>
        ({ words: (node.persistentState as RomProgram).words });
    restorePersistentState = (data: unknown) =>
        data && (data as Partial<RomPersistence>).words ?
            new RomProgram((data as RomPersistence).words) :
            this.initPersistentState();
};

export class RomStateView implements StateView {
    readonly kind = 'ROM';
    constructor(public readonly state: MemoryState, public readonly program: RomProgram) { }
    get addresses() {
        const currentAddr = this.state.address;
        const windowOffset = currentAddr % 4;
        const windowStart = currentAddr - windowOffset;
        return [0, 1, 2, 3].map(ix => ({
                address: windowStart + ix,
                value: this.state.peekState(windowStart + ix),
                isAddress: ix === windowOffset
            }));
    }
    getState() { return undefined; }
}
