import { toBitSet } from "../common/bits";
import { EventEmitter2 } from "../common/eventEmitter";
import { Device } from "./machine";


class LampDeviceInternalState {
    isOn = false;
    reset(): void {
        this.isOn = false;
    }
    set(onFlag: boolean, offFlag: boolean) {
        if (onFlag) {
            this.isOn = true;
        }
        if (offFlag) {
            this.isOn = false;
        }
        return [];
    }
}

export class LampDevice implements Device {
    readonly lamp = new LampDeviceInternalState();
    readonly changed = new EventEmitter2<number>();
    inputState = 0;
    inputMask = 0xFFFF;
    poke(word: number) {
        this.inputState = word;
        const bits = toBitSet(word);
        const on = bits.itemAt(0);
        const off = bits.itemAt(1);
        this.lamp.set(on, off);
        return this.peek();
    }
    peek() { return this.inputState; }
    get lampIsOn() { return this.lamp.isOn; }
    // the IO bus has no built-in memory, so is reset with any tick.
    tick() { this.inputState = 0; }
    reset() { this.lamp.reset(); }
}
