import { Point, Pos } from './position';
import { deleteItem, insertAt, removeWhere } from '../common/utilities';
import { EventEmitter2 } from '../common/eventEmitter';
import { IComponentInstanceState, ComponentType, NodeTypeEvents, IInputConnectorState, ComponentInternalState } from './componentType';
import { Pin, PinGroup, OutputPinDefinition, InputPinDefinition } from './pins';
import { DiagramType } from './diagramMissionType';
import { ConnectorState } from './connectorState';
import { ComponentInstanceState } from './componentState';


export interface Connector {
    index: number;
    pin: Pin;
}

/* A live connector, either on a component or diagram edge */
export class OutputConnector implements Connector {
    constructor(readonly diagram: CircuitStructure, readonly pin: Pin, readonly index: number) { }

    get name() { return this.pin.name; }
    get width() { return this.pin.width; }

    connections: Connection[] = [];

    // called from InputConnector
    addConnectionEnd(connection: Connection) {
        this.connections.push(connection);
    }

    removeConnectionEnd(inputConnector: Connection) {
        deleteItem(this.connections, inputConnector);
    }

    setLabel(label: string) {
        this.pin.name = label;
        this.notifyStructureChanged();
    }
    notifyStructureChanged() {
        if (this.diagram) {
            this.diagram.notifyStructureChanged();
        }
    }
    deleteConnections() {
        for (const connection of this.connections) {
            connection.deleteFromSource();
        }
        this.connections = [];
        this.diagram.notifyStructureChanged();
    }
}

/* the connector at the edge of a diagram */
export class EdgeOutputConnector extends OutputConnector {
    delete() {
        // when pin deleted - delete all connections first
        this.deleteConnections();
    }
}

export class NodeOutputConnector extends OutputConnector {
    constructor(readonly node: ComponentInstance, diagram: CircuitStructure, port: Pin, index: number) {
        super(diagram, port, index);
    }

    notifyStructureChanged() {
        this.diagram.notifyStructureChanged();
    }
}

export type Waypoint = { x: number; y: number };

export class Connection {
    constructor(readonly sourceConnector: OutputConnector,
        readonly targetConnector: InputConnector,
        readonly waypoints: Waypoint[] = []) { }
    /* called before deleting the connection, to notifiy the target end point */
    deleteFromSource() {
        this.targetConnector.connection = undefined;
    }
    delete() {
        this.sourceConnector.removeConnectionEnd(this);
        this.targetConnector.connection = undefined;
    }
    addWaypoint(segmentIx: number, point: Point): Waypoint {
        const waypoint = { x: point.x, y: point.y };
        insertAt(this.waypoints, segmentIx, waypoint);
        return waypoint;
    }
}

export class InputConnector implements Connector {
    constructor(readonly diagram: CircuitStructure, readonly pin: Pin, readonly index: number) { }
    get name() { return this.pin.name; }
    get width() { return this.pin.width; }

    connection: Connection | undefined;

    createConnection(sourceConnector: OutputConnector, waypoints?: Waypoint[]) {
        this.connection = new Connection(sourceConnector, this, waypoints);
        sourceConnector.addConnectionEnd(this.connection);
        this.notifyStructureChanged();
    }
    deleteConnection() {
        if (this.connection) {
            this.connection.delete();
        }
        this.notifyStructureChanged();
    }
    notifyStructureChanged() {
        this.diagram.notifyStructureChanged();
    }
    delete() {
        // delete connections before deleting pins
        if (this.connection) {
            this.deleteConnection();
        }
    }
    setLabel(label: string) {
        this.pin.name = label;
        this.notifyStructureChanged();
    }
}

/* the connector at the edge of a diagram */
export class EdgeInputConnector extends InputConnector { }

export class NodeInputConnector extends InputConnector {
    constructor(readonly node: ComponentInstance, diagram: CircuitStructure, readonly pin: Pin, readonly index: number) {
        super(diagram, pin, index);
    }
    notifyStructureChanged() {
        this.diagram.notifyStructureChanged();
    }
    delete() {
        // delete connections before deleting pins
        if (this.connection) {
            this.deleteConnection();
        }
    }
    setLabel(label: string) {
        this.pin.name = label;
        this.notifyStructureChanged();
    }
}

export type ComponentInstanceEvents = {
    inputPinAdded(cnn: NodeInputConnector): void;
    outputPinAdded(cnn: NodeOutputConnector): void;
    inputPinDeleted(cnn: NodeInputConnector): void;
    outputPinDeleted(cnn: NodeOutputConnector): void;
};

/*  A component placed in a circuit.
 *  Associated with ComponentType which defines the fuctionality.
 *  E.g. "And" is a coponent type, but multiple instances of this component type can exist in the same circuit
 */
export class ComponentInstance implements NodeTypeEvents {
    readonly inputConnectors: NodeInputConnector[];
    readonly outputConnectors: NodeOutputConnector[];
    readonly eventListeners = new Array<ComponentInstanceEvents>();

    constructor(
        readonly diagram: CircuitStructure,
        readonly nodeType: ComponentType, public pos: Pos,
        public persistentState?: unknown) {

        // create connector instances
        this.inputConnectors = nodeType.inputs.flatMap((port) => port.ports).map((port, ix) =>
            new NodeInputConnector(this, diagram, port, ix));
        this.outputConnectors = nodeType.outputs.flatMap((port) => port.ports).map((port, ix) =>
            new NodeOutputConnector(this, diagram, port, ix));
        if (nodeType.eventListeners) {
            // custom nodes may change, so we have to listen for changes
            nodeType.eventListeners.push(this);
        }
    }
    get hasState() { return this.nodeType.hasInternalState; }
    delete() {
        this.diagram.deleteNode(this);
    }
    // callback when a node have been moved
    moved() {
        this.diagram.notifyLayoutChanged();
    }
    // called on a custom component instances when a pin is deleted on the component builder
    inputPinDeleted(port: Pin) {
        const doomedInput = removeWhere(this.inputConnectors, c => c.pin === port)!;
        this.eventListeners.forEach(l => l.inputPinDeleted(doomedInput));
    }
    outputPinDeleted(port: Pin) {
        const doomedOutput = removeWhere(this.outputConnectors, c => c.pin === port)!;
        this.eventListeners.forEach(l => l.outputPinDeleted(doomedOutput));
    }
    inputPinAdded(port: Pin) {
        const cnn = new NodeInputConnector(this, this.diagram, port, this.inputConnectors.length);
        this.inputConnectors.push(cnn);
        this.eventListeners.forEach(l => l.inputPinAdded(cnn));
    }
    outputPinAdded(port: Pin) {
        const cnn = new NodeOutputConnector(this, this.diagram, port, this.outputConnectors.length);
        this.outputConnectors.push(cnn);
        this.eventListeners.forEach(l => l.outputPinAdded(cnn));
    }
    registerInstanceState(st: ComponentInstanceState) {
        this.eventListeners.push(st);
    }
}

export class InputGroupStructure {
    readonly nodes: EdgeOutputConnector[] = [];
    label?: string;
}

export class OutputGroupStructure {
    readonly nodes: EdgeInputConnector[] = [];
    label?: string;
}

/* Event for when a customer component diagram layout is modified
 * Fired on all instanes of the component on other diagrams.
 * E.g. when a pin is removed from the diagram, the pins should
 * also dissapear from all instances of the designed component in other diagrams.
 */
export interface DiagramEvents {
    nodeAdded(node: ComponentInstance): void;
    nodeDeleted(node: ComponentInstance): void;
    inputPinAdded(cnn: EdgeOutputConnector): void;
    outputPinAdded(cnn: EdgeInputConnector): void;
    inputPinDeleted(cnn: EdgeOutputConnector): void;
    outputPinDeleted(cnn: EdgeInputConnector): void;
}

export class CircuitStructure {
    inputGroups: InputGroupStructure[] = [];
    outputGroups: OutputGroupStructure[] = [];
    readonly nodes: ComponentInstance[] = [];
    readonly eventListeners = new Array<DiagramEvents>();
    readonly onStructureChange = new EventEmitter2<unknown>();
    hasCircularConnection = false;

    constructor(inputs: readonly OutputPinDefinition[], outputs: readonly InputPinDefinition[], readonly diagramType?: DiagramType) {
        this.nodes = [];
        this.loadInputs(inputs);
        this.loadOutputs(outputs);
        this.hasCircularConnection = this.detectCircularConnection();
    }

    get hasState() {
        return this.nodes.some(n => n.hasState);
    }

    get inputNodes() { return this.inputGroups.flatMap((grp: InputGroupStructure) => grp.nodes); }
    get outputNodes() { return this.outputGroups.flatMap(grp => grp.nodes); }

    loadInputs(inputs: readonly OutputPinDefinition[]) {
        let ix = 0;
        for (const portDef of inputs) {
            const group = new InputGroupStructure();
            if (portDef instanceof PinGroup) {
                group.label = portDef.name;
                for (const subPort of portDef.ports) {
                    const node = new EdgeOutputConnector(this, subPort, ix);
                    group.nodes.push(node);
                    ix++;
                }
            } else {
                const node = new EdgeOutputConnector(this, portDef, ix);
                group.nodes.push(node);
                ix++;
            }
            this.inputGroups.push(group);
        }
    }

    loadOutputs(outputs: readonly InputPinDefinition[]) {
        let ix = 0;
        for (const portDef of outputs) {
            const group = new OutputGroupStructure();
            if (portDef instanceof PinGroup) {
                group.label = portDef.name;
                for (const subPort of portDef.ports) {
                    const node = new EdgeInputConnector(this, subPort, ix);
                    this.outputNodes.push(node);
                    group.nodes.push(node);
                    ix++;
                }
            } else {
                const node = new EdgeInputConnector(this, portDef, ix);
                this.outputNodes.push(node);
                group.nodes.push(node);
                ix++;
            }
            this.outputGroups.push(group);
        }
    }

    clearCanvas() {
        // remove free nodes
        while (this.nodes.length > 0) {
            this.deleteNode(this.nodes.first());
        }
        // remove direct connections from input to output
        for (const inp of this.inputNodes) {
            inp.deleteConnections();
        }
    }
    addNode(nodeType: ComponentType, pos: Pos, persistentState?: unknown): ComponentInstance {
        const node = new ComponentInstance(this, nodeType, pos, persistentState);
        this.nodes.push(node);
        this.eventListeners.forEach(el => el.nodeAdded(node));
        this.onStructureChange.fire(this);
        return node;
    }

    deleteNode(node: ComponentInstance) {
        // remove connections
        for (const connector of node.outputConnectors) {
            connector.deleteConnections();
        }
        for (const connector of node.inputConnectors) {
            connector.deleteConnection();
        }
        // remove node itself
        deleteItem(this.nodes, node);
        this.eventListeners.forEach(el => el.nodeDeleted(node));
        this.onStructureChange.fire(null);
    }

    addInputPin(label: string, width: number) {
        const group = new InputGroupStructure();
        const node = new EdgeOutputConnector(this, new Pin(width, label), this.inputNodes.length);
        group.nodes.push(node);
        this.inputGroups.push(group);
        this.eventListeners.forEach(el => el.inputPinAdded(node));
        this.onStructureChange.fire(null);
    }

    addOutputPin(label: string, width: number) {
        const group = new OutputGroupStructure();
        const node = new EdgeInputConnector(this, new Pin(width, label), this.outputNodes.length);
        group.nodes.push(node);
        this.outputGroups.push(group);
        this.eventListeners.forEach(el => el.outputPinAdded(node));
        this.onStructureChange.fire(null);
    }
    deleteAllComponentsOfType(nodeType: ComponentType): void {
        this.nodes
            .filter(n => n.nodeType === nodeType)
            .forEach(n => this.deleteNode(n));
    }
    deleteInputPin(pin: EdgeOutputConnector) {
        pin.delete();
        for (const group of this.inputGroups) {
            deleteItem(group.nodes, pin);
        }
        this.inputGroups = this.inputGroups.filter(g => g.nodes.length > 0);
        this.eventListeners.forEach(el => el.inputPinDeleted(pin));
        this.notifyStructureChanged();
    }
    deleteOutputPin(pin: EdgeInputConnector) {
        pin.delete();
        for (const group of this.outputGroups) {
            deleteItem(group.nodes, pin);
        }
        this.outputGroups = this.outputGroups.filter(g => g.nodes.length > 0);
        this.eventListeners.forEach(el => el.outputPinDeleted(pin));
    }
    // callback from connectors
    notifyStructureChanged() {
        // detect if
        this.hasCircularConnection = this.detectCircularConnection();
        this.onStructureChange.fire(null);
    }
    // callback from component
    notifyLayoutChanged() {
        // We dont distinguish between structure and layout, we want to save all changes
        this.notifyStructureChanged();
    }
    detectCircularConnection() {
        const visited = new Set<ComponentInstance>();
        function hasCircularDep(node: ComponentInstance) {
            const chain = new Set<ComponentInstance>();
            function traverseDeps(node: ComponentInstance): boolean {
                if (chain.has(node)) {
                    return true;
                }
                chain.add(node);
                visited.add(node);
                for (const conn of node.inputConnectors) {
                    if (conn.connection && conn.connection.sourceConnector instanceof NodeOutputConnector) {
                        const depNode = conn.connection.sourceConnector.node;
                        const found = traverseDeps(depNode);
                        if (found) {
                            return found;
                        }
                    }
                }
                return false;
            }
            return traverseDeps(node);
        }
        for (const node of this.nodes) {
            if (visited.has(node)) {
                continue;
            }
            if (hasCircularDep(node)) {
                return true;
            }
        }
        return false;
    }
}

/*
 *  A "dummy" node model for nodes in the palette.
 *  Does not have any state, but displays the connectors.
 *
 *  Does not need to support the mutaton events, since the palette is re-rendered
 *  when switching levels. So they will always be updated when visible.
 */

export class PaletteComponentInstance implements IComponentInstanceState {
    inputConnectorStates: PaletteInputConnector[];
    outputConnectorStates: PaletteOutputConnector[];
    pos = new Pos(0, 0);
    oscillating = false;
    internalState: ComponentInternalState;

    constructor(readonly nodeType: ComponentType) {
        /* palette components does not have internal state,
        * we supply this 'dummy' state. */
        this.internalState = new class implements ComponentInternalState {
            reset() { /* nothing - stateless node */ }
            // should never be called
            resolveOutputs = (_node: ComponentInstanceState): ConnectorState[] => [];
        };

        // create connector instances
        this.inputConnectorStates = nodeType.inputs.flatMap((port) =>
            port.ports.map((subPort, subIx) => new PaletteInputConnector(subPort, subIx)));
        this.outputConnectorStates = nodeType.outputs.flatMap((port) =>
            port.ports.map((subPort, subIx) => new PaletteOutputConnector(subPort, subIx)));
    }
    moveTo(pos: Pos) { this.pos = pos; }
    moved() { /* Never called, since palette nodes are deleted when dropped */ }
    delete() { /* Never called, since palette nodes are never added to diagram */ }
}

class PaletteInputConnector implements IInputConnectorState, Connector {
    constructor(readonly pin: Pin, readonly index: number) { }
    oscillating = false;
    get name() { return this.pin.name; }
    get width() { return this.pin.width; }
    state = 0;
    numState = 0;
    connection = undefined;
}

class PaletteOutputConnector implements Connector {
    constructor(readonly pin: Pin, readonly index: number) { }
    oscillating = false;
    state = 0;
    numState = 0;
    connection = undefined;
    get name() { return this.pin.name; }
    get width() { return this.pin.width; }
}

