import { diagram } from './missions';
import { nandNodeType, invNodeType, selector16NodeType } from 'diagram/missions/logicMissions';
import { incNodeType, zeroNodeType } from 'diagram/missions/arithmeticMissions';
import { SequentialTest, Step } from '../sequentialTest';
import { word, bit, pins } from '../pins';
import { dff16NodeType, RegisterState } from './registerMission';
import * as Word16 from '../../common/arithmetics';
import { ComponentInternalState } from '../componentType';
import { BaseBuiltinComponentType } from './baseNodeType';
import { depends } from './dependency';
import { ComponentInstanceState } from 'diagram/componentState';


export const counterMission = diagram({
    key: 'COUNTER',
    inputPins: [bit('st'), word('X'), bit('cl')],
    outputPins: [word('')],
    palette: [nandNodeType, invNodeType, dff16NodeType, incNodeType, selector16NodeType, zeroNodeType],
    tests: [
        // store, X, clock
        new SequentialTest([
            Step.set([1, 7, 0], 'Set <b>st</b> to 1, <b>X</b> to 7 and <b>cl</b> to 0. This should set <b>in</b> to 7'),
            Step.setAllowUnstable('cl', 1, ''),
            Step.setPin('cl', 0, 'Change <b>cl</b> to 0 (clock cycle complete). Should set <b>out</b> to <b>in</b>. Output should be <b>out</b>'),
            Step.assertOutput('', 7),
            Step.set([0, 7, 1], 'Set <b>st</b> to 0'),
            Step.set([0, 7, 0], 'Set <b>cl</b> to 0'),
            Step.set([0, 7, 1], 'Set <b>cl</b> to 1'),
            Step.assertOutput('', 8),
        ]),
        new SequentialTest([
            Step.set([0, 0, 0],
                'Set <b>st</b> to 0 and <b>cl</b> to 0. We expect <b>out</b> to be 0 and <b>in</b> to be <b>out</b> + 1, which should be 1. Output should be <b>out</b>'),
            // Step.assertOutput('', 0),
            Step.setAllowUnstable('cl', 1, ''),
            Step.setPin('cl', 0, 'Change <b>cl</b> to 0 (clock cycle complete). Should set <b>out</b> to <b>in</b>. Output should be <b>out</b>'),
            Step.assertOutput('', 1),
            Step.setPin('cl', 1, 'Change <b>cl</b> to 1. We expect <b>in</b> to be <b>out</b> + 1 which should be 2'),
            Step.setPin('cl', 0, 'Change <b>cl</b> to 0. Should set <b>out</b> to <b>in</b>. Output should be <b>out</b>'),
            Step.assertOutput('', 2)
        ]),
    ],
    score: { min: 4 },
} as const);

class CounterState implements ComponentInternalState {
    readonly register = new RegisterState();
    readonly stateView = this.register.stateView;
    resolveOutputs(node: ComponentInstanceState) {
        const st = node.inputConnectorStates.itemAt(0).bitState;
        const data = node.inputConnectorStates.itemAt(1).numState;
        const clock = node.inputConnectorStates.itemAt(2).bitState;
        return this.resolve(st, data, clock);
    }
    resolve(st: boolean, data: number, clock: boolean) {
        let next;
        if (!st) {
            next = Word16.add(this.register.output, 1);
        } else {
            next = data;
        }
        return this.register.resolve(true, next, clock);
    }

    reset() {
        this.register.reset();
    }
}

export const counterNodeType = new class extends BaseBuiltinComponentType {
    readonly name = 'counter';
    readonly key = 'COUNTER';
    readonly inputs = pins(bit('st'), word('X'), bit('cl'));
    readonly outputs = pins(word());
    readonly hasInternalState = true;
    readonly depends = depends(counterMission);
    readonly createInternalState = () => new CounterState();
};

