export {};

declare global {
    interface Array<T> {
        min(): T;
        max(): T;
        first(): T;
        first(f: (i: T) => boolean): T;
        single(): T;
        single(f: (i: T) => boolean): T;
        itemAt(index: number): T;
    }
}
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
if (!Array.prototype.min) {
    Array.prototype.min = function<T>(this: Array<T>) { return this.reduce((p, v) => (p < v ? p : v)); };
}
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
if (!Array.prototype.max) {
    Array.prototype.max = function<T>(this: Array<T>) { return this.reduce((p, v) => (p > v ? p : v)); };
}
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
if (!Array.prototype.first) {
    Array.prototype.first = function<T>(this: Array<T>, f?: (i: T) => boolean) {
        const item = f === undefined ? this[0] : this.find(f);
        if (item === undefined) {
            throw new Error('No matching item found');
        }
        return item;
    };
}
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
if (!Array.prototype.single) {
    Array.prototype.single = function<T>(this: Array<T>, f?: (i: T) => boolean) {
        if (f === undefined) {
            if (this.length !== 1) {
                throw new Error(`Expected an array with a single item but was ${this.length} `)
            }
            return this[0];
        }
        const matches = this.filter(f);
        if (matches.length === 0) {
            throw new Error('No matching item found');
        }
        if (matches.length > 1) {
            throw new Error('More than one match found');
        }
        return matches[0];
    };
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
if (!Array.prototype.itemAt) {
    Array.prototype.itemAt = function<T>(this: Array<T>, index: number) {
        const value = this.at(index);
        if (value == undefined) {
            throw new Error(`Index out of range: ${index}`);
        }
        return value;
    };
}
